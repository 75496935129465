import React from 'react';

interface ITextInputProps {
  value: string | number | null | undefined,  
  [key: string]: any  
}

const TextInput = ({ value,type, ...rest } : ITextInputProps) => (
  <input type={type || "text"} value={value || ''} {...rest} />
)
export default TextInput;