import React from 'react';

export default class Switch extends React.Component<any> {
    
    
    toggleChecked = () => {
        
        this.props.onChange(this.props.name, !this.props.isChecked);
    }
    render(){
        
        return (
            <div>
                <label className="switch">
                    <input type="checkbox" onChange={this.toggleChecked} checked={this.props.isChecked || false}  disabled={this.props.disabled || false} />
                    <span className="slider round"></span>                    
                </label>                
                <span className="slider-yes-no">{this.props.isChecked ? 'Yes':'No'}</span>        
            </div>
        )
    }
}