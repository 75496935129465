import React, { useState } from 'react'
import FormTextItem from '../form/FormTextItem';
import { FaSpinner } from 'react-icons/fa';
import { GenerateReportRequest } from '../../model/GenerateReportRequest';
import api from '../../api/api';
import useAsyncEffect from 'use-async-effect';
import { AdminReportView } from '../../model/AdminReportsView';
import ReportItem from './ReportItem';
import moment from 'moment'
import FormSwitchItem from '../form/FormSwitchItem';
import FormDateItem from '../form/FormDateItem';
// import styled from 'styled-components';

interface IProps {
    ticker:string
}

// const Root = styled.div`
// display:flex;
// `

const ReportConfig:React.FC<IProps> = (props:IProps) => {
    
    const now = new Date();
    const defaultStartDate = moment(now).subtract(1,'months').startOf('month').format('DD-MMM-YYYY');
    const defaultEndDate = moment(now).subtract(1,'months').endOf('month').format('DD-MMM-YYYY');
    
    const [ startDate, setStartDate ] = useState(defaultStartDate);
    const [ endDate, setEndDate ] = useState(defaultEndDate);

    const [ peers, setPeers ] = useState('');
    const [ priceOverlay, setPriceOverlay ] = useState('');
    const [ comparativeTradingActivity, setComparativeTradingActivity ] = useState(false);
    const [ reports, setReports ] = useState<AdminReportView[]>([]);
    const [ saving, setSaving ] = useState(false);
   
    
    const createReport = async () => {
        setSaving(true)

        let request:GenerateReportRequest = {
            Ticker: props.ticker,
            StartDate: startDate,
            EndDate: endDate,
            Peers: peers,
            ComparativeTradingActivity : comparativeTradingActivity,
            PriceOverlay : priceOverlay,            
        }
        let result = await api().generateReport(request);
        setSaving(false)
        
        if(result.errorMessage){
            alert(result.errorMessage)             
        }else{
            window.open(`/report/${result.report.guid}`);
            setReports( [ result.report, ...reports])
        }
    }

    

    useAsyncEffect(async (isMounted:()=>boolean) => {

        let result = await api().getReports(props.ticker);
        if(isMounted()){            
            if(result.reports.length){
                const mostRecentReport = result.reports[0];
                setPeers(mostRecentReport.peers)                
                setStartDate(mostRecentReport.startDate)                
                setEndDate(mostRecentReport.endDate)   
                setComparativeTradingActivity(mostRecentReport.comparativeTradingActivity)             
                setPriceOverlay(mostRecentReport.priceOverlay)             
            }
            setReports(result.reports)
            
        }   

    }, [])
    
    return <div>
        <FormDateItem label="Start date" name='startDate' value={startDate} onChange={(e:any)=>setStartDate(e.target.value)}/>
        <FormDateItem label="End date" name='endDate' value={endDate} onChange={(e:any)=>setEndDate(e.target.value)}/>

     
        <FormTextItem label="Peers" name='peers' value={peers} onChange={(e)=>setPeers(e.target.value)}/>

        <FormSwitchItem label="Comparative Trading Activity" name="comparativeTradingActivity" value={comparativeTradingActivity} onChange={(name:string, value:string|boolean) => setComparativeTradingActivity(value as boolean)}/>

        <FormTextItem label="Price Overlay" name='priceOverlay' value={priceOverlay} onChange={(e)=>setPriceOverlay(e.target.value)}/>
        
        
        

      

        <button className="saveButton" onClick={createReport} disabled={saving}> 
            <span>{saving ? 'Generating...':'Generate'}</span>
                {saving && <FaSpinner className="fa-spin" size={16} color="white" />}
        </button>

        <hr/>

        <h2>Existing Reports</h2>

        { !!reports.length && <ul>
            { reports.map((x,i)=> <li key={i}>
                <ReportItem item={x} />
            </li>)}
            </ul>}

    </div>
}

export default ReportConfig;