import DashboardStock from "../model/DashboardStock";

const defaultState:DashboardStock[] = [];
const reducer = (state = defaultState, action:{ type:string, value: DashboardStock[]}) => {
  switch(action.type){
    case 'SET_STOCKS':
      return action.value;
    
    default:
      return state;
  }
}

export default reducer;