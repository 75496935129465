import React from 'react'
import * as Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { BrokerActivityViewModel } from '../../model/BrokerActivityViewModel';
import SortableTable from '../table/SortableTable'

interface IProps {
    view:BrokerActivityViewModel   
    showTable?:boolean 
}


const TotalValueColumnName = 'Total Value';
const NetValueColumnName = 'Net Value';
const SellValueTradedColumnName = 'Sell Value Traded';
const BuyValueTradedColumnName = 'Buy Value Traded';

const headers:string[] = [
    'Broker',
    'Active Rank',
    TotalValueColumnName,
    NetValueColumnName,
    'Net Vol',
    'Sells',
    'Sell Vol',
    'Sell Price',
    SellValueTradedColumnName,
    'Buys',
    'Buy Vol',
    'Buy Price',    
    BuyValueTradedColumnName,    
    'Trades',
    'Traded %',    
]

const BrokerActivitySingle:React.FC<IProps> = (props:IProps)=>{
    
    
    
    const getOptions = (view:BrokerActivityViewModel):Highcharts.Options => {
        let suffix = ''
        if(view.sells.filter(x=>x % 1000000 === 0).length === 0 && view.buys.filter(x=>x % 1000000 === 0).length === 0){
            suffix = '($m)'
        }
        return {
            chart: {
                type: 'bar'
            },
            title: {
                text: `Trading Activity in ${view.brokerShareAnalysis.ticker}`
            },
            
            
            xAxis: [{
                categories: view.names,
                reversed: false,
                labels: {
                    step: 1
                },
                
            }, { // mirror axis on right side
                opposite: true,
                reversed: false,
                categories: view.names,
                linkedTo: 0,
                labels: {
                    step: 1
                },
                
            }],
            yAxis: {
                title: {
                    text: null
                },
                
                
            },
        
            plotOptions: {
                series: {
                    stacking: 'normal'
                }
            },
        
            
            series: [{
                name: `Sell ${suffix}`,
                data: view.sells,
                type: 'bar',
                color: '#FC3333',
            }, 
            {
                name: `Buy ${suffix}`,
                data: view.buys,
                type: 'bar',
                color: '#33A86B',
            }
        ]
        }
    }       


    let data:string|number[][] = [];
    if(props.showTable){
        
        let index = 0;
        for(let row of props.view.brokerShareAnalysis.items){
            const arr:any[] = [
                row.name,
                index,
                row.totVal,
                row.netVal,
                row.netVol,
                row.sells,
                row.sellVol,
                row.sellPrice,
                row.sellValue,
                row.buys,
                row.buyVol,
                row.buyPrice,                
                row.buyValue,                
                row.trades,
                row.tradedPercent
            ];
            index++
            data.push(arr);
        }
       
    }

    const cellFormatter = (columnName:string, cell:any):React.ReactNode => {
        
        if(columnName===TotalValueColumnName
            || columnName===NetValueColumnName
            || columnName===SellValueTradedColumnName
            || columnName===BuyValueTradedColumnName){
                let strVal = cell;
                
                if(typeof (cell) === 'number'){
                    strVal = '$' + (cell as number).toLocaleString();    
                }
                
                
            return <>{strVal}</>
        }
        return null;
    }


    return <>
    <HighchartsReact
            highcharts={Highcharts}
            options={getOptions(props.view)}
        />

       { props.showTable &&  <>
        <hr/>
        <SortableTable headers={headers} data={data} sortIndex={1} cellFormatter={cellFormatter} />

       </>
       }

    </>
    
    
}

export default BrokerActivitySingle;