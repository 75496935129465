import React from 'react';
import Switch from '../Switch';
import { toFormName } from '../../util/util';


interface IFormSwitchItemProps{
    label:string;
    name?:string;
    value:boolean;
    onChange:(name:string, value:string|boolean)=>void;
    disabled?:boolean;
    children?:any;
}
const FormSwitchItem = (props:IFormSwitchItemProps) => {
    const name = props.name || toFormName(props.label);
    return (
    
        <div className="form-item">
            <label htmlFor="">{props.label}</label>   
            <Switch isChecked={props.value} name={name} onChange={props.onChange} disabled={props.disabled}/>            
        </div>
    )
}

export default FormSwitchItem;