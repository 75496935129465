import api from '../api/api';
import Stock from '../model/Stock';
import { loading } from './Loading';
import IMarketData from '../model/IMarketData';

export const startSetStock = (ticker:string) => {
    return async (dispatch:any)=>{
        dispatch(loading(true))
        let stock = await api().getStock(ticker);
        dispatch(setStock(stock))
        dispatch(loading(false))
    }
}

export const saveMarketData = (ticker:string, m:IMarketData) => {
    return async (dispatch:any)=>{
        await api().saveMarketData(ticker, m);
    }
}

export const startSaveStock = (s:Stock) => {
    return async (dispatch:any)=>{       
        // eslint-disable-next-line 
        let result = await api().saveStock(s);
        if(result.success){
            dispatch(setStock(result.stock))
        }
        return Promise.resolve(result);
        //dispatch(resetStock())
        //
    }
}

const setStock = (stock:Stock)=>({
    type: 'SET_STOCK',
    value: stock  
})

export const resetStock = ()=>({
    type: 'RESET_STOCK',
})
