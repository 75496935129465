import React, { useState } from 'react';
import api from '../api/api';
import AnimatedSaveButton from './AnimatedSaveButton';
import { XeroPageViewModel } from '../model/XeroPageViewModel';
import useAsyncEffect from 'use-async-effect';
import { AdminInvoiceResultItem } from '../model/AdminInvoiceResult';
import { Invoice } from '../model/Invoice';
import { getInvoiceUrl, invoiceStringGetter } from '../util/util';
import styled from 'styled-components';
import { AssignXeroInvoicesRequestItem } from '../model/AssignXeroInvoicesRequest';
import DashboardStock from '../model/DashboardStock';
import { getDisplayUrl } from './dashboard/DashboardHelpers';

const Style = styled.div`
margin-top: 1rem;

.repeating-invoice {
    font-size: 0.6rem;    
}
.invoice-container{
    max-width: 60rem;
}
.invoice-row{
    display: flex;    
    justify-content: space-between;
    align-items: center;
    margin: 1rem 0;
}
.assign-to-select {
    margin-left: 1rem;
    max-width: 10rem;
}
.ignored{
    font-size: 0.6rem;    
}
`;

const IgnoreOption = 'Ignore';

const XeroPage: React.FC = () => {

    useAsyncEffect(async (isMounted) => {
        let settings = await api().getXeroPage();
        let stocks = await api().getStocks();
        if (isMounted()) {
            setView(settings);
            setStocks(stocks.filter(x=>!!x.apiKey));
        }

    }, [])

    const [view, setView] = useState({ repeatingInvoices:[], invoices:[], ignored: [] } as XeroPageViewModel)
    const [items, setItems] = useState<AssignXeroInvoicesRequestItem[]>([])
    const [stocks, setStocks] = useState<DashboardStock[]>([])

    const refreshXero = async () :Promise<boolean> => {
        const result = await api().refreshXero(); 
        setView(result);
        return true;
    }
    const assignXeroInvoices = async () :Promise<boolean> => {
        
        const result = await api().assignXeroInvoices({ items });         
        setView(result);
        return true;
    }

    const onAssignToChanged = (invoiceId:string, value:string) => {
        console.log(`onAssignToChanged invoiceId:${invoiceId} value:${value}`);        
        if(value){
            let item:AssignXeroInvoicesRequestItem= { invoiceId }
            
            if(value === IgnoreOption){
                item.ignore = true;
            }else{
                item.ticker = value;
            }
            const newItems = items.filter(x=>x.invoiceId !== invoiceId);
            newItems.push(item)
            setItems(newItems)

        }else{
            setItems(items.filter(x=>x.invoiceId !== invoiceId))
        }
    }

    const getSelectedItem = (invoiceId:string) => {
        const item = items.find(x=>x.invoiceId === invoiceId);
        if(item != null){
            if(item.ignore){
                return IgnoreOption;                
            }else if(item.ticker){
                return item.ticker;                
            }
        }
        return "";
    }
    
    const companyStringGetter = (stock:DashboardStock) : string => {
        const companyName = !!stock.companyName && stock.companyName !== stock.ticker ? stock.companyName : '';
        return `${stock.ticker}:${stock.exchange} ${ getDisplayUrl(stock.websiteUrl)} ${companyName}`;
    }

    return <Style className="padding">

            <div>
                    <AnimatedSaveButton onClick={refreshXero} startingText="Refresh Xero" progressText="Refreshing..." completedText="Refreshed!" />
            </div>

            <div>
                <h2>Unassigned Repeating Invoices</h2>
                { !view.repeatingInvoices.length && <span>None</span>}                
                { view.repeatingInvoices.map((x:AdminInvoiceResultItem,index:number) => {
                    return <div className="repeating-invoice" key={index} data-id={x.id}>{x.text}</div>
                })}
            </div>


            <div className="invoice-container">
                <h2>Unassigned Invoices</h2>
                { !view.invoices.length && <span>None</span>}
                { view.invoices.map((x:Invoice) => {
                    return <div key={x.invoiceID} className="invoice-row" data-id={x.invoiceID}>
                        <a target="_blank"
                            href={getInvoiceUrl(x.invoiceID)}
                            rel="noopener noreferrer"
                        >     <span>{invoiceStringGetter(x)}</span>
                        </a>
                        
                        <select 
                        className="assign-to-select" 
                        onChange={(e: React.ChangeEvent<HTMLSelectElement>)=> onAssignToChanged(x.invoiceID, e.target.value)}
                        value={getSelectedItem(x.invoiceID)}
                        >
                            <option value=""> --Assign To--</option>
                            <option >{IgnoreOption}</option>
                            { stocks.map((stock,j) => {
                                return <option key={j} value={`${stock.ticker}:${stock.exchange}`}>{companyStringGetter(stock)}</option>
                            })}
                            
                        </select>
                    </div>
                })}


                <div>
                <div>
                    <AnimatedSaveButton onClick={assignXeroInvoices} startingText="Assign Invoices" progressText="Wait..." completedText="Assigned!" />
            </div>

                </div>

            </div>



            <div className="invoice-container">
                <h2>Ignored Invoices</h2>
                { !view.ignored.length && <span>None</span>}
                { !!view.ignored.length && <pre className="ignored">
                        {view.ignored.map((x:Invoice) => <div>{invoiceStringGetter(x)}</div>)}
                        </pre>}


                <div>
               
                </div>

            </div>


    </Style>
}

export default XeroPage;