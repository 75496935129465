import React from 'react';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
 
const ConfirmEx : (text:string) => Promise<boolean> = (text:string) => {

    return new Promise((resolve, reject)=>{
        confirmAlert({
            customUI: ({ onClose }) => {
              return (
                <div className='custom-ui'>
                  <h1>Are you sure?</h1>
                  <p>{text}</p>
                  <button onClick={()=>{
                      onClose();
                      resolve(false);
                  }}>No</button>
                  <button
                    onClick={() => {
                        
                      onClose();
                      resolve(true);
                    }}
                  >
                    Yes
                  </button>
                </div>
              );
            }
          });
    })

}

export default ConfirmEx;

