import React, { useImperativeHandle, useState } from 'react';
import { FaTimes } from 'react-icons/fa';
import moment from 'moment-timezone';
import FormTextItem from '../form/FormTextItem';
import { Announcement } from '../../model/Stock';
import FormSwitchItem from '../form/FormSwitchItem';
import AlertEx from '../AlertEx';
import * as Exchange from '../../model/Exchange'
import api, { combinedTicker } from '../../api/api';

interface IProps{
    onClose: ()=>void;
    onSavedAnnouncement: ()=>void;
    announcement:Announcement;
    ticker:string;
    exchange:string;
}

export interface IAnnouncementsMethods {
    save:()=>Promise<boolean>
}

const dateFormat = 'D MMM YYYY h:mm A';

const EditAnnouncement = React.forwardRef<any, IProps>((props:IProps, ref) => {

    const [ headline, setHeadline ] = useState<string>(props.announcement ? props.announcement.headline : '');
    const [ date, setDate ] = useState<string>((props.announcement ? moment(props.announcement.date).tz('Australia/Sydney') : moment.tz('Australia/Sydney')).format(dateFormat));

    const [ priceSensitive, setPriceSensitive ] = useState<boolean>(props.announcement ? props.announcement.priceSensitive : false);
    const [ remotePath, setRemotePath ] = useState<string>((props.announcement ? props.announcement.remotePath : '') || '');
    const [ localPath ] = useState<string>((props.announcement ? props.announcement.localPath : '') || '');
    
    const [ videoUrl, setVideoUrl ] = useState<string>(props.announcement ? props.announcement.videoUrl||'' : '');
    const [ videoDescription, setVideoDescription ] = useState<string>(props.announcement ? props.announcement.videoDescription||'' : '');    
    
    useImperativeHandle(ref, ():IAnnouncementsMethods => ({

        async save():Promise<boolean> {
            const existingAnnouncement = props.announcement ? { ...props.announcement } : null;
        
        let file: File|null = newAnnouncementPdf != null && newAnnouncementPdf!.length ? newAnnouncementPdf[0] : null;
        if (props.exchange === "ASX" && !existingAnnouncement && !file) {
            await AlertEx('File upload not defined. Must upload a pdf');
          return Promise.resolve(false);
        }

        
        const newAnnouncement = {
            headline,      
            date: moment.tz(date, dateFormat, 'Australia/Sydney').toISOString(),
            priceSensitive,
            remotePath,
            localPath,
            videoUrl,
            videoDescription,
            sendHistory: []
        };
        

        if (!newAnnouncement.headline) {
            await AlertEx('Headline not defined');
          return Promise.resolve(false);
        }
    
        let result = await api().saveAnnouncement(newAnnouncement, existingAnnouncement, file, combinedTicker(props.ticker, props.exchange) );
        if (result.success){
            
            await props.onSavedAnnouncement();
            await AlertEx(`Saved announcement`);
            
            return Promise.resolve(true);

        }else{
            await AlertEx(`Save Failed! ${result.errorMessage}`);
            return Promise.resolve(false);
        }
        
        
        }
    
      }));
    
    const fileInput = React.createRef<any>();
    let newAnnouncementPdf:FileList|null = null;

    const onFileChange = (selectorFiles: FileList|null) =>   {
        newAnnouncementPdf = selectorFiles;
    }
    
    
    const showFileUpload = props.exchange === Exchange.ASX;
    return (
        <div>
            <div className="edit-announcement__header"><FaTimes className="hand" size={32} color="grey" onClick={props.onClose} /></div>
            <p style={{fontSize:'0.7rem', fontStyle:'italic'}}>Time is Sydney EDST</p>
            <div className="input-group">
                <FormTextItem label="Headline" name='headline' value={headline} onChange={(e)=>setHeadline(e.target.value)} />
                <FormTextItem label="Date" name='date' value={date} onChange={(e)=>setDate(e.target.value)} placeholder="e.g. 2019-10-09T08:20" />
                <FormSwitchItem label="Price Sensitive" name="priceSensitive" value={priceSensitive} onChange={(name:string, value:string|boolean) =>setPriceSensitive(value as boolean)}/>
                
                {showFileUpload && <div className="form-item">
                    <label htmlFor="">Select PDF</label>
                    <input type="file" accept=".pdf"
                        ref={fileInput}
                        onChange={(e) => onFileChange(e.target.files)}
                    />
                </div>
                }

                { !showFileUpload && <>
                    <FormTextItem 
                        label="URL" 
                        name='remotePath' 
                        value={remotePath} 
                        onChange={(e)=>setRemotePath(e.target.value)} 
                        placeholder="n/a for ASX" 
                        />
                </>}

                <FormTextItem label="Video Url" name='videoUrl' value={videoUrl} onChange={(e)=>setVideoUrl(e.target.value)}  />
                <FormTextItem label="Video Description" name='videoDescription' value={videoDescription} onChange={(e)=>setVideoDescription(e.target.value)}  />
                
            </div>

        </div>
    )

});


export default EditAnnouncement;