import React, { useState } from 'react';
import { FaSpinner } from 'react-icons/fa';

interface IProps {
    onClick:()=>Promise<boolean>;
    startingText?:string
    progressText?:string
    completedText?:string
    disabled?:boolean
}

const AnimatedSaveButton:React.FC<IProps> = (props:IProps) => {
    
    const startingText = props.startingText || 'Save';
    const progressText = props.progressText || 'Saving...'
    const completedText = props.completedText || 'Saved!'
    const [ saving, setSaving ] = useState(false);
    const [ buttonText, setButtonText ] = useState(startingText);
    

    const save = async ()=>{
        setSaving(true);
        setButtonText(progressText);
        
        const success = await props.onClick();
        saveComplete(success);
    }
    
    const saveComplete = (success:boolean) => {
        setSaving(false);
        setButtonText(success ? completedText : startingText);
        
        setTimeout(()=>{
            setButtonText(startingText);
        }, 3000)
    }

    return (
        <button className="saveButton" onClick={save} disabled={saving || props.disabled}> 
        <span>{buttonText}</span>
            {saving && <FaSpinner className="fa-spin" size={16} color="white" />}
        </button>
    )
}

export default AnimatedSaveButton;