import React, { useState } from 'react'
import moment from 'moment'
import useAsyncEffect from 'use-async-effect';
import api from '../../api/api';
import { ReconcileResult } from '../../model/ReconcileResult'
import AnimatedSaveButton from '../AnimatedSaveButton'

interface IProps {
    id:string
}

const ReconcileResultPage: React.FC<IProps> = (props:IProps) => {
    
    const [reconcileResult, setReconcileResult] = useState<ReconcileResult>();
    
    useAsyncEffect(async (isMounted) => {
        let result = await api().getReconcileResult(props.id);
        if (isMounted()) {
            setReconcileResult(result);
        }

    }, [])

    const apply = async () =>{
        await api().reconcileApply(props.id);
        return true;
    }
    return <>
    <p>Reconcile Result</p>
    { reconcileResult && <>
        
        { !reconcileResult.results.length && <p>No Results</p>}
        
        {reconcileResult.results.map((x,i)=>{
        return <div key={i}>
            <span>{x.ticker}</span>
           { x.newAnnouncements.length && <ul>
            { x.newAnnouncements.map((y,j) => <li key={j}>
            <span>{ moment(y.date).tz('Australia/Sydney').format('D MMM YYYY h:mm A')} </span>
            <span> {y.headline}</span>
            </li>)
            }
           </ul>}
        </div>
    })}
    </>}
   

<AnimatedSaveButton onClick={apply} startingText="Apply" progressText="Applying..." completedText="Done!" />

    </>
}

export default ReconcileResultPage