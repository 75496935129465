import React, { useState } from 'react';
import IMarketData from '../../model/IMarketData';
import NumberInput from '../form/NumberInput';
import { StockData } from '../../model/StockData';
import api from '../../api/api';
import AlertEx from '../AlertEx';
import moment from 'moment';
import { toSydneyDate } from '../../util/util';

interface IProps {
    id:string;
    marketData:IMarketData;
    onChange:(name:string, value:number)=>void;
}

const MarketData:React.FC<IProps> = (props:IProps) => {

    const onChange = (e:any) => {                
        props.onChange(e.target.name, +e.target.value)
    }

    const [stockData, setStockData] = useState<StockData[]>([]);
    const [gettingStockData, setGettingStockData] = useState<boolean>(false);
    const [updatingStockData, setUpdatingStockData] = useState<boolean>(false);
   
    const getStockData = async () => {
        setGettingStockData(true);
        const result = await api().getChartData(props.id);        
        result.stockData.reverse();
        setStockData(result.stockData);
        setGettingStockData(false);        
    }

    const updateStockData = async () => {
        setUpdatingStockData(true);
        const result = await api().updateChartData(props.id);
        if(result.success){
            await getStockData();
            alert('Updated successfully');
        }else{
            AlertEx(result.errorMessage)  
        }
              
        setUpdatingStockData(false);
    }

    let data = props.marketData || {};    
    return <div>
        <div className="input-group">

        <div className="form-item">
                <label htmlFor="">Ask</label>
                <NumberInput value={data.ask} name="ask" onChange={onChange} />
            </div>

        <div className="form-item">
                <label htmlFor="">Bid</label>
                <NumberInput value={data.bid} name="bid" onChange={onChange} />
            </div>

            
            <div className="form-item">
                <label htmlFor="">Last</label>
                <NumberInput value={data.last} name="last" onChange={onChange} />
            </div>


            <div className="form-item">
                <label htmlFor="">Movement</label>
                <NumberInput value={data.movement} name="movement" onChange={onChange} />
            </div>

            <div className="form-item">
                <label htmlFor="">Movement Percent</label>
                <NumberInput value={data.movementPercent} name="movementPercent" onChange={onChange} />
            </div>
            

            <div className="form-item">
                <label htmlFor="">Open</label>
                <NumberInput value={data.open} name="open" onChange={onChange} />
            </div>

            <div className="form-item">
                <label htmlFor="">High</label>
                <NumberInput value={data.high} name="high" onChange={onChange} />
            </div>

            <div className="form-item">
                <label htmlFor="">Low</label>
                <NumberInput value={data.low} name="low" onChange={onChange} />
            </div>

            

            <div className="form-item">
                <label htmlFor="">Volume</label>
                <NumberInput value={data.volume} name="volume" onChange={onChange} />
            </div>
            <div className="form-item">
                <label htmlFor="">Shares On Issue</label>
                <NumberInput value={data.sharesOnIssue} name="sharesOnIssue" onChange={onChange} />
            </div>


            <div className="form-item">
                <label htmlFor="">Market Cap</label>
                <NumberInput value={data.marketCap} name="marketCap" onChange={onChange} />
            </div>

            <div className="form-item">
                <label htmlFor="">Updated</label>
                <input type="text" disabled value={toSydneyDate(data.updated)} />
            </div>
            
        </div>

        <hr></hr>
        <h2>Stock Data</h2>
        <div className="horizontal">
            <button className="button-sm" onClick={getStockData} disabled={gettingStockData}>Display</button>
            <button className="button-sm" onClick={updateStockData} disabled={updatingStockData}>Update</button>
        </div>
        { stockData.length > 0 && <div>
            <p>{stockData.length} rows</p>

            <table>
                <thead>
                    <tr>
                        <th>Date</th>
                        <th>Open</th>
                        <th>High</th>
                        <th>Low</th>
                        <th>Close</th>
                        <th>Volume</th>
                    </tr>
                </thead>
                <tbody>
                    { stockData.map((x,i)=> <tr key={i}>
                        <td>{moment(x.date).format('DD MMM YY')}</td>
                        <td>{x.open}</td>
                        <td>{x.high}</td>
                        <td>{x.low}</td>
                        <td>{x.close}</td>
                        <td>{x.volume}</td>
                    </tr>)}
                </tbody>
            </table>
            </div>}
    </div>
}

export default MarketData;