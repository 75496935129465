import React, { ChangeEvent, useState } from 'react';
import styled from 'styled-components'
import useAsyncEffect from 'use-async-effect';
import Autocomplete from "react-google-autocomplete";

import AnimatedSaveButton from '../AnimatedSaveButton';
import FormTextItem from '../form/FormTextItem';
import ServiceOrder from '../../model/ServiceOrder';
import ServiceOptionItem from './ServiceOptionItem';
import ServiceOption from '../../model/ServiceOption';
import api from '../../api/api';
import FormDateItem from '../form/FormDateItem';

const Style = styled.div`



@media (min-width: 1200px){
    max-width:1200px;
    margin:auto;
}

h2 {
    font-size: 1.2rem;
}
input[type='checkbox'] {
    height: 1.5rem;
}
.content {
    padding: 0 1rem 10rem 1rem;
}
.top-header{
    color:#01133E;
}

.light {
background-color: #00B5D1;
color:#fff;
padding: 0.5rem;
}
header{
    display: flex;
    flex-direction: row-reverse;
}
.form-item {
    margin-right: 1rem;
}
.submit-panel {
    margin-top: 1rem;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
}
.row {
    display: flex;
    flex-direction: column;

    @media (min-width: 1200px){
        flex-direction: row;
    }

    margin: 2rem 0;        
}
.last-row-item {
    flex-basis: 60%;
}
.setup-requirements {
    
    margin: 2rem 0;
    
    label {
        margin-left: 1rem;
    }

    & > div {
        display: flex;
        align-items: center;
    }
}
.margin-top{
    margin-top: 2.5rem;
}
.services-container {
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    @media (min-width: 1200px){
        margin-top: 2.5rem;
        justify-content: space-between;
        flex-direction: row;
    }    
}

.service-option {
    margin-top: 2.5rem;
    @media (min-width: 1200px){
        margin-top: 0;
    }    
}

.provision{
    background-color: #00B5D1;
    color: #fff;
    padding: 1rem;
    margin: 1rem 0
}
.error{
    color: #7b0100;
    background-color: #f5cccc;
    border-color: #f0b3b3;  
    padding: 1rem;
    margin: 1rem 0
}
input[type="radio"] {
    height: 1rem;   
    width :auto;
    margin-right: 1rem;
}
.radio-inline{
    display:flex;
    margin: 1rem 0;
}

`;



const NewOrderPage:React.FC = (props:any) => {
    const showSaveButton = true;
    const [ order, setOrder ] = useState<ServiceOrder>({sendPriceSensitiveAnnouncements:false,sendNonPriceSensitiveAnnouncements:false,autoSendAnnouncementEmail:false,autoSendNonPriceSensitiveAnnouncements:false} as any);
    const [ submitted, setSubmitted ] = useState<boolean>(false);
    const [ serviceOptions, setServiceOptions ] = useState<ServiceOption[]>([]);
    const [ errors, setErrors ] = useState<string[]>([]);        
    
    const [address, setAddress] = useState('');
    
    const onChange = (e:any) => {  
        
        onChanged(e.target.name, e.target.value);         
    }

    


    const onChanged = (name:string, value:any) => {
        
        const clone:any = { ...order }
        clone[name] = value;
        setOrder(clone)  
    }

    const submit:()=>Promise<boolean> = async () => {
        
        setErrors([]);
        const errs:string[] = []
        if(!order.companyName){
            errs.push('Company Name Required')
        }
        if(!order.ticker){
            errs.push('Ticker/ASX Code Required')
        }
        if(!order.website){
            errs.push('Website Required')
        }
        if(!address){
            errs.push('Address Required')
        }
        if(!order.contactName){
            errs.push('Contact Name Required')
        }
        if(!order.contactPhone){
            errs.push('Contact Phone Required')
        }

        if(!order.contactEmail){
            errs.push('Contact Email Required')
        }
        if(!order.abn){
            errs.push('ABN Required')
        }
        if(!order.authorisedBy){
            errs.push('Authorised By Required')
        }
        if(errs.length){
            setErrors(errs);
            return false;
        }
        order.address = address;
        await api().saveServiceOrder(order);        
        setSubmitted(true);

        return true;
    }

    useAsyncEffect(async (isMounted)=>{
        const result = await api().getServiceOptions(props.match.params.id);
        if(isMounted()){
            setServiceOptions(result.options);
            onChanged('option', result.options.find(x=>x.isDefault))
        }
    }, []);

    
   const autoSendConfigurationChanged = (e:ChangeEvent<HTMLInputElement>) => {
    const clone:ServiceOrder = { ...order }
        
    clone.sendPriceSensitiveAnnouncements = true;
    clone.sendNonPriceSensitiveAnnouncements = true;
    clone.autoSendAnnouncementEmail = false;
    clone.autoSendNonPriceSensitiveAnnouncements = false;

    if(e.target.value === 'all'){
        clone.autoSendAnnouncementEmail = true;
        clone.autoSendNonPriceSensitiveAnnouncements = true;
    }
    else if(e.target.value === 'price_sensitive_only'){
        clone.autoSendAnnouncementEmail = true;
    }

    setOrder(clone)  
    console.log('clone', clone)
   }


    
    const disabled = submitted;



    return <Style>

        <header className="header">
            <img src="/images/logo.svg" alt="logo" className="front-page-logo" />
        </header>

        <div className="content">

        <h2 className='top-header'>INVESTI SERVICES PTY LTD (ABN 81 619 772 968) ORDER FORM</h2>
        <p>Please complete the following details for implementation.</p>

        <h2>GENERAL</h2>
        <ul>
            <li>ASX Stock data and announcements are updated with a 20 minute delay.</li>
        </ul>
        <p>Services will be invoiced in advance on an annual basis. Payment is required within 7days from issue of
            invoice to ensure no interruption to ongoing service.</p>

        <h2>CANCELLATION POLICY</h2>

        <p>This form is an order form requesting us to commence work based on your requirements. In submitting
            this order you agree to pay at a minimum the setup charges if you cancel. The term of services is
            12 months, notice for cancellation must be provided in writing to contact@investi.com.au prior to the
            commencement of the next billing cycle.</p>


        <h2 className='margin-top'>COMPANY DETAILS</h2>

        <div className='row'>
            <FormTextItem label="Name of Company" name='companyName' value={order.companyName} onChange={onChange} disabled={disabled} required />
            <FormTextItem label="Ticker/ASX Code" name='ticker' value={order.ticker} onChange={onChange} disabled={disabled} />
            <FormDateItem label="Listing date (IPO only)" name='listingDate' value={order.listingDate} onChange={onChange} disabled={disabled} />            
            <FormTextItem label="URL (Address) of Company Website where the data will appear." name='website' value={order.website} onChange={onChange} className="last-row-item" disabled={disabled} />
        </div>

        <div className='row'>
            <FormTextItem label="Billing Contact Name" name='contactName' value={order.contactName} onChange={onChange} disabled={disabled} />
            <FormTextItem label="Billing Contact Phone" name='contactPhone' value={order.contactPhone} onChange={onChange} disabled={disabled} />
            <FormTextItem label="Billing E-Mail" name='contactEmail' value={order.contactEmail} onChange={onChange} className="last-row-item" disabled={disabled} />
        </div>
        <div className='row'>
            <FormTextItem label="ABN" name='abn' value={order.abn} onChange={onChange} disabled={disabled} />
            {/* <FormTextItem label="Billing address" name='address' value={order.address} onChange={onChange} className="last-row-item" disabled={disabled} /> */}
            <div className="form-item last-row-item">
            <div><label htmlFor="">Billing address</label></div>
            <Autocomplete 
                    defaultValue={address}                    
                    apiKey="AIzaSyB_NyqA_6mTi-VqMdL18rO0uEm8YRKve4k"                    
                    placeholder={"Please enter the address"}                    
                    //onChange={e=> onChanged('address', (e.target as HTMLInputElement).value)}                    
                    onChange={e=> setAddress((e.target as HTMLInputElement).value)}                    
                    
                    onPlaceSelected={p => {                        
                        //onChanged('address', p.formatted_address || p.name)
                        setAddress(p.formatted_address || p.name)
                    }}                    
                    options={ {types: ['address']}}
                    
                    />
            </div>
            
            
        </div>

        <h2 className='margin-top'>SERVICES</h2>

        <div className='services-container'>
            {serviceOptions.map((x, i) => <ServiceOptionItem
                key={i}
                index={i + 1}
                isChecked={order.option != null && order.option.name === x.name}
                item={x}
                checkChanged={() => onChanged('option', x)}
                disabled={disabled}
            ></ServiceOptionItem>)}



        </div>

        <hr />
            
        <div>
        <h2 className='margin-top'>Mailchimp/Campaign monitor settings (optional)</h2>

       
        <div >
            <label className="radio-inline">
                <input type="radio" value="all" name="autoSendConfiguration" onChange={autoSendConfigurationChanged} checked={order.autoSendAnnouncementEmail && order.autoSendNonPriceSensitiveAnnouncements} /> Auto send ALL announcements
            </label>
            <label className="radio-inline">
                <input type="radio" value="price_sensitive_only" name="autoSendConfiguration" onChange={autoSendConfigurationChanged} checked={order.autoSendAnnouncementEmail && !order.autoSendNonPriceSensitiveAnnouncements} /> Auto send price sensitive only
            </label>
            <label className="radio-inline">
                <input type="radio" value="manual" name="autoSendConfiguration" onChange={autoSendConfigurationChanged} checked={!order.autoSendAnnouncementEmail && !order.autoSendNonPriceSensitiveAnnouncements && order.sendPriceSensitiveAnnouncements && order.sendNonPriceSensitiveAnnouncements} /> Manually confirm each announcement
            </label>
        </div>

                    <div className='row'>
        <FormTextItem label="Confirmation Emails" helperText="Recipients will receive the Mailchimp 'Send Now' email"  name='confirmationEmail' value={order.confirmationEmail!} onChange={onChange}/>                    
        </div>
        

        </div>
        
        

        <hr style={{marginTop:'6rem'}}/>

        {showSaveButton && <div className='submit-panel'>            
            <AnimatedSaveButton onClick={submit} startingText="Submit Order Form" progressText="Submitting" completedText="Submitted" disabled={disabled} />
            <FormTextItem label="Authorised By (your name)" name='authorisedBy' value={order.authorisedBy} onChange={onChange} disabled={disabled} />
        </div>
        }

        {!!errors.length && <div className='error'>
            <ul >
                {errors.map((x, i) => <li key={i}>{x}</li>)}
            </ul>
        </div>}


        {submitted && <div className='provision'>
            <p>Thank you for your order</p>
            <p>We will provision your service shortly and email you the API Key and development implementation details</p>
        </div>}

        </div>

    </Style>
}

export default NewOrderPage;