import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Tab from './Tab';

class Tabs extends Component {
  static propTypes = {
    children: PropTypes.instanceOf(Array).isRequired,
  }

  
  
  fireOnTabChanged = (tab)=>{
    if(this.props.onTabChanged){
      this.props.onTabChanged(tab);
    }    
  }

  onClickTabItem = (tab) => {
    this.fireOnTabChanged(tab);
  }

  render() {
    const {
      onClickTabItem,
      
    } = this;
    const activeTab = this.props.activeTab;
    let children = this.props.children.filter(c=>typeof(c) === 'object');
    
    return (
      <div className="tabs">
        <ol className="tab-list">
          {children.map((child, i) => {                                    
            const label = child.props["data-label"];
            return (
              <Tab
                activeTab={activeTab}
                key={label}
                label={label}
                onClick={onClickTabItem}
              />
            );
          })}
        </ol>
        <div className="tab-content">
          {children.map((child) => {
            if (child.props["data-label"] !== activeTab) return undefined;
            return child.props.children;
          })}
        </div>
      </div>
    );
  }
}

export default Tabs;