import React from 'react';
import styled from 'styled-components'
import ServiceOption from '../../model/ServiceOption';


const Style = styled.div`


`;

interface IProps {
    index:number
    isChecked:boolean
    checkChanged:()=>void
    item: ServiceOption
    disabled : boolean
}

const ServiceOptionItem:React.FC<IProps> = (props:IProps) => {
    const price = new Intl.NumberFormat('en-AU', { style: 'currency', currency: 'AUD', minimumFractionDigits: 0 }).format(props.item.price);
    return <Style className='service-option'>

            <div>
                <input type="checkbox" onChange={()=>{ props.checkChanged()}} checked={props.isChecked} disabled={props.disabled}  />
                <h2 className='light'><span>OPTION {props.index} - </span>
                   {price}  + GST annual subscription
                    </h2>

                <ul>
                    { props.item.lineItems.map((x,i)=> <li key={i}>{x}</li>)}                    
                </ul>
            </div>

    </Style>
}

export default ServiceOptionItem;