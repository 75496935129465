import React from 'react';
import ReactDOM from 'react-dom';
import 'normalize.css'
import './styles/styles.scss';
import { Store } from 'redux';
import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-redux';
import AppRouter from './routers/AppRouter';
import configureStore from './store/configureStore';
import { setup } from './api/api';
import IState from './model/IState';
import api from './api/api';
import { SetUser } from './actions/SetUser';
import { to } from './util/util';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';

const store:Store<IState> = configureStore();
setup(store);

serviceWorker.unregister();
export const history = createBrowserHistory();

( async () => {
  const [ err, result ] = await to(api().refreshToken());
  if(result && result.user && !err){
    store.dispatch(SetUser(result.user))
  }


  ReactDOM.render(
    <React.StrictMode>
      <Provider store={store}>
        <Router history={history}>
          <AppRouter />
        </Router>
      </Provider>

    </React.StrictMode>,
    document.getElementById('root')
  );

  
})();
