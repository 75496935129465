import React from 'react'
import * as Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { ReportViewModel } from '../../model/ReportViewModel'
import styled from 'styled-components';
import moment from 'moment';
import SortableTable from '../table/SortableTable';
import { FaExclamation } from 'react-icons/fa';

interface IProps {
    view:ReportViewModel
}


const Section = styled.section`
.has-announcement-on-date {
    color:red;
    margin-left:1.5rem;   
}
`;

 
const DateColumn = 'Date'
const headers:string[] = [
    DateColumn,
    'Trade Count',
    'Trade Value $',
    'Avg Trade Size $',
    // 'VWAP',
    'High $',
    'Low $',
    'Close $'
]

const TradesSummary:React.FC<IProps> = (props:IProps)=>{
    
    const colors = Highcharts.getOptions().colors!;
    const { view } = props;
    const options: Highcharts.Options = {
        chart: {
            zoomType: 'xy'
        },
        title: {
            text: `Trade Count &amp; Average Trade Size in ${view.ticker.toUpperCase()}.ASX`
        },
        subtitle: {
            text: `Period: (${view.startDate} - ${view.endDate})`
        },
        xAxis: [{
            categories: view.tradeAnalysis.items.map(x=>moment(x.day).format('D MMM')),
            crosshair: true
        }],
        yAxis: [{ // Primary yAxis
            labels: {
                format: '{value}',
                style: {
                    color: colors[0]
                }
            },
            title: {
                text: 'Average Trade size',
                style: {
                    color: colors[1]
                }
            },
            opposite: true
        }, { // Secondary yAxis
            title: {
                text: 'Trade Count',
                style: {
                    color: colors[0]
                }
            },
            labels: {
                format: '{value}',
            },
            
        }],
        tooltip: {
            shared: true
        },
        legend: {
            layout: 'vertical',
            align: 'left',
            x: 120,
            verticalAlign: 'top',
            y: 0,
            floating: true
            
        },
        series: [{
            name: 'Trade Count',
            type: 'column',
            yAxis: 1,
            //data: [49.9, 71.5, 106.4, 129.2, 144.0, 176.0, 135.6, 148.5, 216.4, 194.1, 95.6, 54.4],
            data: view.tradeAnalysis.items.map(x=>x.numTrades),
            
        }, 
        {
            name: 'Average Trade Size ($k)',
            type: 'spline',
            data: view.tradeAnalysis.items.map(x=>x.avgSize/1000),
            
        }]
    }

    
    const data:any[][] = [];
    for(let row of view.tradeAnalysis.items){
        const arr:any[] = [
            new Date(row.day),
            row.numTrades,
            row.trVal,
            row.avgSize,
            // row.vwap,
            row.high,
            row.low,
            row.close,
        ]
        data.push(arr)
    }

    const cellFormatter = (columnName:string, cell:any):React.ReactNode => {
        if(columnName===DateColumn){
            const item = view.tradeAnalysis.items.find(x=>new Date(x.day).getTime() === cell.getTime())!
            let title = '';
            if(item.announcements.length > 0){
                title = item.announcements.map(x=>x.headline).join('\n');
            }
            return <span title={title}>
                {moment(cell).format('DD MMM YY')}  
                { !!title && <span className="has-announcement-on-date"><FaExclamation /></span> }
             </span>
        }
        return null;
    }

    
    return <Section>
        <h3>Trades</h3>                
        <HighchartsReact
            highcharts={Highcharts}
            options={options}
        />

        <hr/>

        <SortableTable headers={headers} data={data} cellFormatter={cellFormatter} />

        


    </Section>
    
    
    
}

export default TradesSummary;