import React, { useState } from 'react';
import useAsyncEffect from 'use-async-effect';
import { loading } from '../../actions/Loading';
import api from '../../api/api';
import { useDispatch } from 'react-redux';
import { AnnouncementCommentsResult } from '../../model/AnnouncementCommentsResult';
import CommentGroupItem from './CommentGroupItem';
import styled from 'styled-components';

interface IProps{
    combinedTicker:string
}

const Wrapper = styled.div`
.comment-groups-container{

}

`;

const EditAnnouncementComments: React.FC<IProps>= (props:IProps) => {
    const dispatch = useDispatch();
    const [ item, setItem ] = useState<AnnouncementCommentsResult>();
    const [ showComments, setShowComments ] = useState<boolean>(true);

    useAsyncEffect(async (isMounted:()=>boolean)=> {
        dispatch(loading(true));
        const result = await api().getAnnouncementCommentsResult(props.combinedTicker);
        if(isMounted()){
            setItem(result)   
        }
        dispatch(loading(false));
        
      }, []);

     

    return <div>

{ item && <Wrapper>
<p>Total number of comments for {item.combinedTicker}: {item.commentGroups.length} <span onClick={e=>setShowComments(!showComments)}>[{showComments ? 'Hide':'Show'}]</span></p>
<div className='comment-groups-container'>
{ showComments && item.commentGroups.map((x,i)=> <CommentGroupItem key={i} commentGroup={x}/>)}
</div>

</Wrapper>}

    </div>
}

export default EditAnnouncementComments