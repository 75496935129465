import React from 'react';

interface INumberInputProps {
  value: number,  
  [key: string]: any
}

const NumberInput = ({ value, ...rest }: INumberInputProps) => (
  <input type="number" value={typeof(value)==='number' ? value : ''} {...rest} />
)

export default NumberInput;