import moment from 'moment';
import React, { useState } from 'react'
import styled from 'styled-components'

const Table = styled.table`
th {
    cursor: pointer;
}
`;

interface IProps {
    headers:string[]
    data:any[][]
    sortIndex?:number
    cellFormatter?:(columnName:string, cellData:any) => React.ReactNode
}



const SortableTable:React.FC<IProps> = (props:IProps) => {
    const [ sortIndex, setSortIndex ] = useState(props.sortIndex || 0);
    const [ sortOrder, setSortOrder ] = useState('asc');
    
    // const foo:Date = new Date();
    // foo.getTime()
    
    const { headers, data } = props;
    data.sort((arr1:any[], arr2:any[]) => {
        if(typeof(arr1[sortIndex]) === 'number'){
            if(sortOrder === 'asc'){
                return arr1[sortIndex] - (arr2[sortIndex]);
            }else{
                return arr2[sortIndex] - (arr1[sortIndex]);
            }
            
        } else if(typeof(arr1[sortIndex]) === 'string'){
            if(sortOrder === 'asc'){
                return arr1[sortIndex].localeCompare(arr2[sortIndex]);
            }else{
                return arr2[sortIndex].localeCompare(arr1[sortIndex]);
            }            
        }else if(arr1[sortIndex] instanceof Date){
            if(sortOrder === 'asc'){
                return arr1[sortIndex].getTime() - arr2[sortIndex].getTime();
            }else{
                return arr2[sortIndex].getTime() - arr1[sortIndex].getTime();
            }            
        }
        return 0;
    })
    
    const onHeaderClicked = (index:number) => {
        setSortIndex(index)
        setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc')
    }

    const defaultCellFormatter = (columnName:string, cell:any): React.ReactNode=>{
        let strVal = cell;
        if(typeof (cell) === 'number'){
            strVal =  (cell as number).toLocaleString();
        }else if(cell instanceof Date){
            strVal = moment(cell).format('D MMM YY')
        }
        return <>{strVal}</>
    }

    return <Table>
        <thead>
            <tr>
                {headers.map((header, i) => <th key={i} onClick={()=>onHeaderClicked(i)}>{header}
                    { sortIndex === i && <>
                    { sortOrder === 'asc' ? <> &#x25B2;</> : <> &#x25BC;</> } 
                    </>}
                    
                </th>)}

            </tr>
        </thead>
        <tbody>
            {data.map((row, index) => {
                return <tr key={index}>
                    {row.map((cell, j) => {
                        
                        const formatter = props.cellFormatter || defaultCellFormatter;
                        const columnName = headers[j];
                        const tsx = formatter(columnName, cell) || defaultCellFormatter(columnName, cell);
                        
                        return <td key={j}>{tsx}</td>
                    })}


                </tr>
            })}

        </tbody>
    </Table>
}

export default SortableTable