import React, { useImperativeHandle, useState } from 'react';
import FormTextItem from '../form/FormTextItem';
import { EmailMarketingSettings } from '../../model/Stock';
import useAsyncEffect from 'use-async-effect';
import api from '../../api/api'
import { MailChimpSubscriberCountResult } from '../../model/MailChimpSubscriberCountResult';
import TextInput from '../form/TextInput';
import AnimatedSaveButton from '../AnimatedSaveButton';
import { MailChimpApiTestCampaignArgs } from '../../model/MailChimpApiTestCampaignArgs';
import { SaveEmailMarketingSettingsRequest } from '../../model/SaveEmailMarketingSettingsResult';
import AlertEx from '../AlertEx';
import { useSelector } from 'react-redux';
import { IGlobalState } from '../../model/IGlobalState';
import * as Roles from '../../model/Roles';
import { HasAdminRole, IsAdminOrHasRole } from '../../model/Roles';

interface IProps {
    ticker:string;
    apiKey:string;
    stockId:string;
    
}

export interface IMailChimpMethods {
    save:()=>Promise<boolean>
}


const Mailchimp = React.forwardRef<any, IProps>((props:IProps, ref) => {       
    
    const [settings, setSettings ] = useState<EmailMarketingSettings>({} as any);
    const [subscriberCount, setSubscriberCount ] = useState<number | undefined>();
    const [subscriberError, setSubscriberError ] = useState<string>();
    const [previewUrl, setPreviewUrl ] = useState<string>();
    const user = useSelector((state: IGlobalState) => state.user);
    
    useAsyncEffect(async (isMounted)=>{

        const result = await api().getEmailMarketingSettings(props.stockId);
        setSettings(result)
    }, [  ])

    useImperativeHandle(ref, ():IMailChimpMethods => ({

        async save() {
                        
            const request:SaveEmailMarketingSettingsRequest = {
                StockId: props.stockId,
                Settings: settings
            }
            const result = await api().saveEmailMarketingSettings(request);
            if(result.success){
                setSettings(result.settings)
                AlertEx('Saved!');
            }else{
                AlertEx(result.errorMessage);
            }
            
            return true;  
                
        }
    
      }));

    const onChange = (e:any) => {                        
        const s1 = { ...settings } as any;
        s1[e.target.name] = e.target.value;                
        setSettings(s1);      
    }

    const sendToSubscribers = async ():Promise<boolean> => {
        const args = sendTestCommon('Send to subscribers');
        if(!args){
            return false;
        }
        const result = await api().sendToSubscribers(args);
        if(result.errorMessage){
            alert(result.errorMessage);
        }else{
            alert('Test sent successfully.')
        }
        return true;
    }

    const sendTestEmail = async ():Promise<boolean> => {
        const args = sendTestCommon('Send test email');
        if(!args){
            return false;
        }
        const result = await api().sendTestEmail(args);
        if(result.errorMessage){
            alert(result.errorMessage);
        }else{
            alert('Campaign created successfully.')            
            setPreviewUrl(result.previewUrl);
        }
        return true;
    }
    
    const sendTestCommon = (type:string):MailChimpApiTestCampaignArgs|undefined => {
        if(!settings){
            return;
        }
        if(!props.apiKey && settings.mailChimpApiKey){
            alert('You must define BOTH the investi API Key and the mailchimp key')
            return;
        }
        if(!window.confirm(`${type} for ${props.ticker}. Are you sure?`)){
            return;
        }
        const args:MailChimpApiTestCampaignArgs = {
            apiKey: props.apiKey,
            listId: settings.mailChimpListId,
            templateId: settings.mailChimpTemplateId,
            replyTo: settings.mailChimpReplyTo || '',
            fromName: settings.mailChimpFromName || '',
            clientId: settings.campaignMonitorClientId || '',
            testEmailAddresses: settings.testEmailAddresses || '',
        }
        return args;
    }

    useAsyncEffect(async (isMounted)=>{

        setSubscriberError('');
        setSubscriberCount(undefined);

        if(settings.mailChimpApiKey && settings.mailChimpListId){
            
            let result:MailChimpSubscriberCountResult = await api().mailchimpSubscriberCount(settings.mailChimpApiKey, 
                settings.mailChimpListId, settings.campaignMonitorClientId||'', props.stockId);
            if(isMounted()){
                if(result.success){
                    setSubscriberCount(result.count)
                }else{
                    setSubscriberError(result.message);
                }
            }
        }

    }, [ settings.mailChimpApiKey, settings.mailChimpListId ])
    

    
    return <div className="input-group">
        <FormTextItem label="List Id" name='mailChimpListId' value={settings.mailChimpListId} onChange={onChange} />
        
        <div className="form-item">
            <label htmlFor="">Api Key</label>
            <TextInput value={settings.mailChimpApiKey} name='mailChimpApiKey' onChange={onChange}  />
            { subscriberCount !== undefined && <span>Subscriber Count: {subscriberCount}</span> }
            { subscriberError && <span className="error">{subscriberError}</span> }
        </div>
        

        <FormTextItem label="Template Id" name='mailChimpTemplateId' value={settings.mailChimpTemplateId} onChange={onChange} />
        <FormTextItem label="Reply To" name='mailChimpReplyTo' value={settings.mailChimpReplyTo} onChange={onChange} />
        <FormTextItem label="From Name" name='mailChimpFromName' value={settings.mailChimpFromName} onChange={onChange} />
        <FormTextItem label="Saved Segment Id" name='mailChimpSavedSegmentId' value={settings.mailChimpSavedSegmentId} onChange={onChange} />
        <FormTextItem label="Client Id (Campaign Monitor only)" name='campaignMonitorClientId' value={settings.campaignMonitorClientId} onChange={onChange} />
        <FormTextItem label="Test Email Addresses" name='testEmailAddresses' value={settings.testEmailAddresses} onChange={onChange} />

        <hr/>

        <div className='send-test-container'>

            { IsAdminOrHasRole(user, props.stockId, Roles.TestSendAnnouncement) && <div className='send-test-item'>
            <p>Send Test Email <small>Creates a Campaign and sends it to "Test Email Addresses" above using the most recent announcement.</small></p>
            
            <AnimatedSaveButton onClick={sendTestEmail} startingText="Send Test Email" progressText="Creating..." completedText="Done" />

            { previewUrl && <p><a href={previewUrl} target="_blank" rel="noreferrer">Preview Url</a></p>}
        </div>
}
        
        { HasAdminRole(user) && <div className='send-test-item'>
            <p>Send To Subscribers <small>The most recent announcement will be used. ListId limited to 5 subscribers</small></p>
            <AnimatedSaveButton onClick={sendToSubscribers} startingText="Send To Subscribers" progressText="Sending..." completedText="Done" />            
        </div>}
        
        
        </div>
        

        
    </div>
});

export default Mailchimp