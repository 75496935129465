import React, { useState } from 'react';
import moment from 'moment-timezone';
import { FaCaretDown, FaMailchimp, FaExclamation, FaExternalLinkAlt } from 'react-icons/fa';
import { Announcement } from '../../model/Stock';
import { toSydneyDate } from '../../util/util';
import api, { getCombinedTicker } from '../../api/api';
import { useDispatch, useSelector } from 'react-redux';
import { IGlobalState } from '../../model/IGlobalState';
import { HasAdminRole, IsAdminOrHasRole } from '../../model/Roles';
import * as Roles from '../../model/Roles';
import ConfirmEx from '../ConfirmEx';
import AlertEx from '../AlertEx';
import { startSetStock } from '../../actions/CurrentStock';


interface IProps {
    announcement: Announcement;
    onAnnouncementEdit:(announcement:Announcement)=>void;
}

const AnnouncementItem: React.FC<IProps> = (props: IProps) => {

    const [menuVisible, setMenuVisible] = useState<boolean>(false);
    const user = useSelector((state: IGlobalState) => state.user)
    const stock = useSelector((state: IGlobalState) => state.currentStock)
    const dispatch = useDispatch();
    
    const toggleMenu = (): void => {
        setMenuVisible(!menuVisible)
    }

    const getExternalLink = (announcement:Announcement) : string => {          
        const localPath:string = announcement.localPath!;
        if (!(announcement.localPath||"").startsWith("/api/")){
            return announcement.remotePath!;
        }
        
        if(window.location.hostname !== 'localhost'){
            let parts = window.location.hostname.split('.');
            parts.shift();
            return `https://${parts.join('.')}${localPath}`;            
        }
        return api().getUrl(localPath);              
      }

      const onDelete = async (announcement:Announcement) : Promise<void> => {
        
                
        if (await ConfirmEx('You are about to delete an announcement for ' + stock.ticker)) {
            let result = await api().deleteAnnouncement(getCombinedTicker(stock), announcement);
            console.log('result', result)
            if(result.success){
                window.location.reload();
            }else{
                alert(`Error deleting announcement: ${result.errorMessage}`)
            }
            
        }
    }

    const sendConfirmationEmail = async (ann: Announcement): Promise<void> => {
        const send = await ConfirmEx(`Send the announcement  confirmation email to  ${stock.confirmationEmail}`);
        if(send){
            let apiResult = await api().sendConfirmationAnnouncementEmail(getCombinedTicker(stock), ann)

            if (apiResult.success) {
                
                AlertEx('Sent!');
            }
            else {
                AlertEx(apiResult.errorMessage);
            }
        }
    }

    const onSendEmail = async (ann: Announcement): Promise<void> => {

        let sendOverride = false;
        if (ann.sent) {
            const result:boolean = await ConfirmEx('You have already sent this announcement! Please confirm you wish to re-send')
            if (!result)
                return;
            sendOverride = true;
        }
        
        let sendAnnouncement = false;
        if(!sendOverride){
            sendAnnouncement = await ConfirmEx('You are manually sending an announcement for ' + stock.ticker);
        }
        
        if (sendAnnouncement || sendOverride) {

            const combinedTicker = getCombinedTicker(stock);
            let apiResult = await api().sendAnnouncement(combinedTicker, ann, sendOverride)

            if (apiResult.success) {
                // const newStock = { ...stock };
                // let sentAnnouncement = stock.announcements.find(x => x.headline === ann.headline && x.date === ann.date);
                // sentAnnouncement!.sent = true;
                // setStock(newStock);
                dispatch(startSetStock(combinedTicker))
                AlertEx('Sent!');
            }
            else {
                AlertEx(apiResult.errorMessage);
            }
        }
    }

      const onAnnouncementAction = async (announcement:Announcement, action:AnnouncementAction) =>{
        if(action==='delete'){
            await onDelete(announcement)
        }else if(action==='sendEmail'){
            await onSendEmail(announcement)
        } else if(action==='sendConfirmationEmail'){
            await sendConfirmationEmail(announcement)
        } else if(action === 'edit'){
            await props.onAnnouncementEdit(announcement);
        }
    }

      
    const menu = () => {
        return (
            <>
                <button className="button-sm" onClick={(e) => onAnnouncementAction(props.announcement, "edit")}>Edit</button>
                { IsAdminOrHasRole(user, stock.id, Roles.SendMailchimp) && <button className="button-sm" onClick={(e) => onAnnouncementAction(props.announcement, "sendEmail")}>Send Email</button>}
                { HasAdminRole(user) && <button className="button-sm" onClick={(e) => onAnnouncementAction(props.announcement, "sendConfirmationEmail")}>Send Confirmation Email</button>} 
                {!props.announcement.deletedOn && <button className="button-sm" onClick={(e) => onAnnouncementAction(props.announcement, "delete")}>Delete</button>}
            </>
        )
    }


    const getTitle = (announcement: Announcement): string => {
        let title = '';
        for (let meta of announcement.sendHistory || []) {
            const date = moment(meta.timestamp);
            title += `Sent to Mailchimp by ${meta.emailAddress} on ${date.format('D MMM YYYY')} at ${date.format('h:mm A')}\n`
        }
        return title;
    }

    const announcement = props.announcement;
    const headlineChanged = announcement.headlineOriginal && announcement.headline !== announcement.headlineOriginal
    const externalLink = getExternalLink(announcement);

    return <div className="announcement-row">
        <div>
            <div className="announcement-row__header">
                <span className="announcement-row__date">{toSydneyDate(announcement.date)}</span>
                {announcement.sent &&
                    <span className="announcement-row__sent" title={getTitle(announcement)}>
                        <FaMailchimp />
                    </span>
                }
                {announcement.priceSensitive &&
                    <span className="announcement-row__price-sensitive" title="Price Sensitive"><FaExclamation /></span>
                }

            </div>
            <div>
                <div className="announcement-row__headline">
                    <span className={!!announcement.deletedOn ? " announcement-row__headline-text-deleted" : ""}>{announcement.headline}</span>

                    <a href={externalLink}
                        rel="noopener noreferrer"
                        className="announcement-row__pdflink"
                        target="_blank">
                        <FaExternalLinkAlt size={12} color="grey" />
                    </a>
                    {announcement.deletedOn && <span className=' announcement-row__deleted'>Deleted on: {moment(announcement.deletedOn).tz('Australia/Sydney').format('D MMM YYYY h:mm A')}</span>}
                </div>
                {headlineChanged && <div className="announcement-row__headlineOriginal">Originally: {announcement.headlineOriginal}</div>}
            </div>

        </div>
        <div className="announcement-row__menu">
            <button className="button-sm" onClick={toggleMenu}>
                <FaCaretDown /></button>

            {menuVisible && menu()}
        </div>
    </div>
}

export default AnnouncementItem;

export type AnnouncementAction = "delete" | "edit" | "sendEmail" | "sendConfirmationEmail"