import React, { useState } from 'react';
import styled from 'styled-components'
import useAsyncEffect from 'use-async-effect';
import api, { combinedTicker } from '../../api/api';
import { GetUserResult, SaveUserRequest } from '../../model/GetUserResult';
import AnimatedSaveButton from '../AnimatedSaveButton';
import UserPermissionRow from './UserPermissionRow';
import { ReactSearchAutocomplete } from 'react-search-autocomplete';
import { UserPermission } from '../../model/UserPermission';
import { SaveUserResult } from '../../model/GetUsersResult';
import TextInput from '../form/TextInput';

const Style = styled.div`

.row {
    margin: 1rem 0;
}
.save-panel{
    margin-top: 1rem;
}
.add-row {
    font-size: 0.7rem;
    display:flex;
    flex-direction: row-reverse;
    margin: 1rem 0;
}
.add-row-button{
    align-self: center;
    margin-left: 1rem;
}
.table{
    margin: 0.5rem 0;
}
.username, .password{
    max-width:400px;
}
.field{
    margin: 1rem 0;
    
    & > input {
        margin-top: 0.5rem;
    }
}

`;

interface IProps {
    
    match?: { params: { id: string, tab:string }};
}


interface IAutoCompleteItem {
    id:string
    name:string
    combinedTicker:string
    companyName:string
}
const UserPage:React.FC<IProps> = (props:IProps) => {
    
    const id = props.match!.params.id;

    const [ rows, setRows ] = useState<UserPermission[]>([]);
    const [ items, setItems ] = useState<IAutoCompleteItem[]>([]);
    const [ selectedItem, setSelectedItem ] = useState<IAutoCompleteItem|undefined>(undefined);
    const [ username, setUsername ] = useState<string>('');
    const [ password, setPassword ] = useState<string>('');
    const isNew = id === 'new';

    useAsyncEffect(async (isMounted)=>{
        if(!isNew){
            const result:GetUserResult = await api().getUser(id);
            if(isMounted()){
                setRows(result.permissions)
                setUsername(result.username);
            }
        }
        
        const stocks = await api().getStocks();
        if(isMounted()){
            const arr:IAutoCompleteItem[] = stocks.map(x=>({ 
                id: x.id, 
                name: combinedTicker(x.ticker, x.exchange)+ ' ' + x.companyName,
                combinedTicker: combinedTicker(x.ticker, x.exchange),
                companyName: x.companyName
            }));
            setItems(arr)
        }
    }, []);

    const handleOnSelect = (e:IAutoCompleteItem) => {
        if(e){
            const item:IAutoCompleteItem = items.find(x=>x.id===e.id)!;
            setSelectedItem(item);
        }
        else{
            setSelectedItem(undefined)
        }
        
      }
    
    const save = async ()=>{
        const user:SaveUserRequest = {
            username,
            permissions: rows,
            password
        }
        const saveUserResult:SaveUserResult = await api().saveUser(id, user);
        if(!saveUserResult.success){
            alert(saveUserResult.errorMessage);
            return false;
        }else{
            console.log('saveUserResult', saveUserResult)
            if(isNew){
              window.location.href = '/' + saveUserResult.id;
            }
            return true;
        }
        
    }
    const add = async ()=>{
        if(selectedItem){
            if(rows.filter(x=>x.combinedTicker===selectedItem.combinedTicker).length){
                alert('Row with this Company already exists')
                return false;
            }else{
                setRows([ ...rows, {combinedTicker: selectedItem.combinedTicker, companyName:selectedItem.companyName, roles: []}])
                return true;
            }
            
        }else{
            alert('Please select a Company')
        }
        
    }

    const onCheckChanged = (combinedTicker:string, role:string, isChecked:boolean)=>{
        setRows(rows.map(x=>{
            if(x.combinedTicker === combinedTicker){
               const roles = x.roles.filter(x=>x !== role);
               if(isChecked){
                roles.push(role);
               }
               
                return {
                    ...x,
                    roles 
                }
            }else{
                return x;
            }
            
        }))
        return
    }

    return <Style>
        <h2>User</h2>
       
            <div className='field'><div className='field-label'>Username: </div> 
            <TextInput value={username}
            onChange={(e:React.ChangeEvent<HTMLInputElement>)=>setUsername(e.target.value)} 
            disabled={!isNew} 
            className="username"
            placeholder="Email address"
            />
            </div>

            <div className='field'><div className='field-label'>Password: </div> 
            <TextInput value={password}
            onChange={(e:React.ChangeEvent<HTMLInputElement>)=>setPassword(e.target.value)} 
            className="password"
            placeholder="Leave blank unless changing"
            />
            </div>

            <div className='add-row'>
            <button className='add-row-button' onClick={add} >Add Row</button>
                 <div style={{width:'350px'}}><ReactSearchAutocomplete
                 styling={{ fontSize: "16px", borderRadius: '0'}}
            items={items}
            onSelect={handleOnSelect}
            placeholder='Choose Company'
            fuseOptions={{threshold: 0, ignoreLocation: true}}
          /></div>
                  </div>
            <table className='table'>
                <thead><tr>
                    <th>Ticker</th>
                    <th>Company Name</th>
                    <th>Permission</th>
                    </tr></thead>
                <tbody>

                { rows.map((x,i)=> <tr key={i}><UserPermissionRow permission={x} onCheckChanged={onCheckChanged} /></tr>)}

                </tbody>
            </table>
    

        <div className='save-panel'>
        <AnimatedSaveButton onClick={save} />
        

        </div>

    </Style>
}

export default UserPage;