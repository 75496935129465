import React, { useState } from 'react'
import { ReportViewModel } from '../../model/ReportViewModel';
import api from '../../api/api';
import useAsyncEffect from 'use-async-effect';
import BrokerActivity from './BrokerActivity';
import PriceComparison from './PriceComparison';
import TradesSummary from './TradesSummary';
import ActivitySummary from './ActivitySummary';
import ReportPeriod from './ReportPeriodSection';
import ComparativeTradingActivityPage from './ComparativeTradingActivityPage';
import styled from 'styled-components';
import ReportSearchBar from './ReportSearchBar';
import { ClientGenerateReportRequest } from '../../model/ClientGenerateReportRequest';

interface IProps {
    
    match?: { params: { id: string, tab:string }};
}

const ReportPageWrapper = styled.div`

/* font-family:"Helvetica Neue", Helvetica, Arial, sans-serif; */
max-width: 1250px;
margin: auto;
section{
    border-bottom: 1px solid grey;
    padding-bottom:1rem;
    margin-bottom: 2rem;
}
margin-top: 50px;

.error {
    color: #7b0100;
    background-color: #f5cccc;
    border-color: #f0b3b3;  
    padding: 1rem;
    margin: 1rem 0
}


`;

const ReportPage:React.FC<IProps> = (props:IProps) => {
    const [ view, setView ] = useState<ReportViewModel>();
    
    const [ generatingReport, setGeneratingReport ] = useState<boolean>(false);
    const [ generatReportErrorMessage, setGeneratReportErrorMessage ] = useState<string>('');
    //const guid = props.match!.params.id;
    const guid = window.location.pathname.replace('/report/', '');
    

    useAsyncEffect(async (isMounted:()=>boolean) => {
        const view = await api().getReport(guid)
        if(isMounted()){
            console.log('view', view)
            setView(view)            
        }
        
    }, [])

    const onGenerateReport = async (startDate:string, endDate:string)=>{
        setGeneratReportErrorMessage('')
        setGeneratingReport(true);
        const request:ClientGenerateReportRequest = {
            Guid:guid,
            StartDate:startDate,
            EndDate:endDate
        }
        const result = await api().clientGenerateReportRequest(request);
        console.log('result', result)
        setGeneratingReport(false);
        if(result.errorMessage){
            setGeneratReportErrorMessage(result.errorMessage)
        }else{
            const view = await api().getReport(result.report.guid)
            setView(view)
            window.history.replaceState(null, "Investi Services", `/report/${result.report.guid}`);
        }
        console.log('result', result)
    }
    
    return <ReportPageWrapper>
        {view && <>
            
            { view.allowSearch && <ReportSearchBar 
                startDate={view.startDate}
                endDate={view.endDate} 
                onGenerateReport={onGenerateReport} 
                generatingReport={generatingReport}
                />
                }
                { generatReportErrorMessage && <p className='error'>{generatReportErrorMessage}</p>}

            { view.activitySummary && 
            <ActivitySummary view={view.activitySummary}/>
            }

            { view.reportPeriod &&
                <ReportPeriod 
                reportPeriod={view.reportPeriod}
                startDate={view.startDate}
                endDate={view.endDate}
                />
            }

            <BrokerActivity views={view.brokerActivityViewModels.filter(x=>x.brokerShareAnalysis.ticker===view.ticker)!} 
                startDate={view.startDate}
                endDate={view.endDate}
            />
            { view.comparativeTradingActivity && <ComparativeTradingActivityPage view={view} /> } 
            <PriceComparison view={view} />
            <TradesSummary view={view} />
            
            <BrokerActivity views={view.brokerActivityViewModels.filter(x=>x.brokerShareAnalysis.ticker!==view.ticker)!} 
                startDate={view.startDate}
                endDate={view.endDate}
                title="Broker Activity (Peers)"
            />

        </>}

    </ReportPageWrapper>
}

export default ReportPage