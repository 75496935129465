import React from 'react';
import { CommentGroup } from '../../model/CommentGroup';
import { Link } from 'react-router-dom';
import { toSydneyDate } from '../../util/util';

interface IProps{
    commentGroup:CommentGroup
}


const CommentGroupItem: React.FC<IProps>= (props:IProps) => {


    return <div>

        <p>{props.commentGroup.announcementHeadline}</p>
        <table>
            <tbody>
            {props.commentGroup.comments.map((x,i)=>{
                return <tr key={i}>
                    <td><Link to={`/AnnouncementCommentResponse/${x.announcementCommentEventGuid}`}>[Edit]</Link></td>
                    <td>{toSydneyDate(x.date)}</td>
                    <td>{x.name}</td>
                    <td>{x.comment}</td>
                    <td>{ !!x.responses.length && x.responses[0].comment}</td>
                     </tr>
            })}
            </tbody>
            
        </table>

    </div>
}

export default CommentGroupItem