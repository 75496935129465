import React from 'react';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
 
const AlertEx : (text:string) => Promise<void> = (text:string) => {

    return new Promise((resolve, reject)=>{
        confirmAlert({
            customUI: ({ onClose }) => {
              return (
                <div className='custom-ui'>
                  <p>{text}</p>
                  <button onClick={()=>{
                      onClose();
                      resolve();
                  }}>Ok</button>
                  
                </div>
              );
            }
          });
    })

}

export default AlertEx;

