import React, { useState } from 'react';
import { Announcement } from '../../model/Stock';
import AnnouncementItem from './AnnouncementItem';
import _ from 'lodash';
import EditAnnouncement, { IAnnouncementsMethods } from './EditAnnouncement';
import { useImperativeHandle } from 'react';
import AnimatedSaveButton from '../AnimatedSaveButton';
import { useSelector } from 'react-redux';
import { IGlobalState } from '../../model/IGlobalState';

interface IProps {
    announcements: Announcement[];
    syncInProgress?: boolean;
    onSavedAnnouncement: () => void;
    resyncAnnouncements?: () => Promise<boolean>;
}



const Announcements = React.forwardRef<any, IProps>((props:IProps, ref) => {
  
  const editAnnouncementPage = React.createRef<IAnnouncementsMethods>();
  const stock = useSelector((state:IGlobalState) => state.currentStock)
    
  useImperativeHandle(ref, ():IAnnouncementsMethods => ({

    save() {
          if(editAnnouncementPage.current)
            return editAnnouncementPage.current.save();
          else{
            alert('Nothing to Save')
            return Promise.resolve(false);
          }
            
    }

  }));

  
    const [count, setCount] = useState(20);
    const [editingAnnouncement, setEditingAnnouncement] = useState<Announcement | null>();
    const [editing, setEditing] = useState<boolean>(false);

    const showMore = () => {

        setCount(Math.min(count + 20, props.announcements.length));
    }

    const showAll = () => {
        setCount(props.announcements.length);
    }

    const onAnnouncementEdit = (announcement:Announcement|null) => {
        setEditingAnnouncement(announcement);
        setEditing(true)
    }



    const onEditClose = () => {
        setEditingAnnouncement(null);
        setEditing(false)
    }

    

    const renderList = () => {
        let announcements = _.take(props.announcements, count);
        let showing = Math.min(count, props.announcements.length);
        return (
            <div>
                { !props.syncInProgress && <div className="row-reverse">
                    <button style={{marginLeft:'10px'}} onClick={()=>onAnnouncementEdit(null)}>Add Announcement</button>
                    { props.resyncAnnouncements && <AnimatedSaveButton onClick={props.resyncAnnouncements} startingText="Resync"  /> } 
                </div>}
                <p style={{fontSize:'0.7rem', fontStyle:'italic'}}>Time is Sydney EDST</p>
                {announcements.map((x: Announcement, index: number) => <AnnouncementItem key={index} announcement={x} onAnnouncementEdit={onAnnouncementEdit}  />)}

                <div className="horizontal">
                    <p>Showing {showing} of {props.announcements.length}</p>
                    <button className="button-sm" onClick={showMore} disabled={count === props.announcements.length}>Show More</button>
                    <button className="button-sm" onClick={showAll} disabled={count === props.announcements.length}>Show All</button>
                </div>
            </div>
        )
    }

    return <div className="padding">
        {editing && <EditAnnouncement
            ref={editAnnouncementPage}
            onClose={onEditClose}
            onSavedAnnouncement={props.onSavedAnnouncement}
            announcement={editingAnnouncement!}
            ticker={stock.ticker}
            exchange={stock.exchange}
            />}

        {!editing && renderList()}

    </div>
});

export default Announcements