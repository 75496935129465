import React, { useState } from 'react'
import api from '../../api/api';
import useAsyncEffect from 'use-async-effect';
import styled from 'styled-components';
import { AnnouncementCommentResponseViewModel } from '../../model/AnnouncementCommentResponseViewModel';
import { toSydneyDate } from '../../util/util';
import FormSwitchItem from '../form/FormSwitchItem';
import FormTextArea from '../form/FormTextArea';
import { FaSpinner } from 'react-icons/fa';
import { AnnouncementCommentResponseRequest } from '../../model/AnnouncementCommentResponseRequest';

interface IProps {
    
    match?: { params: { id: string, tab:string }};
}

const Wrapper = styled.div`
.comment-container{
    margin-top: 1rem;
}
.announcement-headline{
    font-weight: bold;
    font-size: 1.2rem;
}
.comment-request-label{
    font-weight: bold;
}

.response-container{
    margin-top: 2rem;
}
textarea{
    width: 100%;
}
.form-item{
    margin: 2rem 0;
}

`;

const AnnouncementCommentResponsePage:React.FC<IProps> = (props:IProps) => {
    const [ view, setView ] = useState<AnnouncementCommentResponseViewModel>();
    const [ saving, setSaving ] = useState<boolean>(false);
    const [ isPublic, setIsPublic ] = useState<boolean>(true);
    const [ submitSuccess, setSubmitSuccess ] = useState<boolean>(false);
    const [ responseComment, setResponseComment ] = useState<string>('');
    const [ error, setError ] = useState<string>('');
    const [ warning, setWarning ] = useState<string>('');
    
    const guid = window.location.pathname.replace('/AnnouncementCommentResponse/', '');

    useAsyncEffect(async (isMounted:()=>boolean) => {
        const view = await api().getAnnouncementCommentResponse(guid)
        if(isMounted()){
            setView(view)            
            if(view.response){                
                setResponseComment(view.response.comment)
                setWarning(`You responded to this comment on ${toSydneyDate(view.response.date)}. You can continue to make edits`)
            }
            setIsPublic(view.isPublic)
        }
        
    }, [])

    const submit = async ()=>{
        setSaving(true);
        setSubmitSuccess(false)
        setError('')
        const request:AnnouncementCommentResponseRequest = {
            guid: view!.guid,
            comment: responseComment,
            isPublic: isPublic
        }
        const result = await api().postAnnouncementCommentResponseRequest(request)
        setSaving(false);
        setError(result.errorMessage)
        setSubmitSuccess(result.success)
    }
    
    return <Wrapper>
        {view && <>
           
           <div className='comment-container'>
           <div>Comment/Question from ASX Announcement <span className='announcement-headline'>'{view.announcementHeadline}'</span> released {toSydneyDate(view.announcementDate)} <span style={{fontSize:'0.7rem', fontStyle:'italic'}}>(EDST)</span></div>
           
           <div>
            <ul>
                <li><span className='comment-request-label'>Date:</span> {toSydneyDate(view.created)}</li>
                <li><span className='comment-request-label'>Name:</span> {view.request.name}</li>
                <li><span className='comment-request-label'>Email:</span> {view.request.email}</li>
                <li><span className='comment-request-label'>Comment:</span> {view.request.comment}</li>
            </ul>
           </div>
           </div>
           
            <hr />

            { warning && <p className="warning">{warning}</p>}

           <div className='response-container'>
            
            <FormTextArea label="Your response" placeholder='Enter your response here' rows={10} name="responseComment" value={responseComment} onChange={(e) => setResponseComment(e.target.value)} disabled={saving}/>
            
            <FormSwitchItem label="Post publicly" name="isPublic" value={isPublic} onChange={(name:string, value:string|boolean) => setIsPublic(value as boolean)} disabled={saving}  />
            <p><small>Comment can be removed from public view by toggling to 'No'</small></p>

            <button className="saveButton" onClick={submit} disabled={saving}> 
            <span>{saving ? 'Submitting...':'Submit'}</span>
                {saving && <FaSpinner className="fa-spin" size={16} color="white" />}
            </button>
            {error && <p className="error">{error}</p>}
            {submitSuccess && <p className="success">Response submitted successfully</p>}
           </div>
        </>}

    </Wrapper>
}

export default AnnouncementCommentResponsePage