import React, { useState } from 'react';
import api from '../../api/api';
import { AdminInvoiceResult, AdminInvoiceResultItem } from '../../model/AdminInvoiceResult';
import useAsyncEffect from 'use-async-effect';
import styled from 'styled-components';
import { Invoice } from '../../model/Invoice';
import { SaveInvoiceDataRequest } from '../../model/SaveInvoiceDataRequest';
import { useImperativeHandle } from 'react';
import { getRepeatingInvoiceUrl, invoiceStringGetter } from '../../util/util';
import { Link } from 'react-router-dom';
import AnimatedSaveButton from '../AnimatedSaveButton';
import ServiceOrder from '../../model/ServiceOrder';
import ServiceOrderItem from '../servicesOrderForm/ServiceOrderItem';
import FormTextItem from '../form/FormTextItem';

interface IProps {
    ticker:string
    stockId:string
}

const Style = styled.div`

.refresh-xero-container {
    display:flex;
    flex-direction: row-reverse;
}
.select-invoice {
    display:flex;
    justify-content: space-between;
    align-items: center;    
}
.add-invoice-button {
    margin-left:15px;
}
.invoices {
    margin: 40px 0;
}
li{
    margin: 10px 0;
}
.repeating-invoice-link{
    font-size:0.6rem;
}
.repeating-invoice-contact{
    font-size:0.6rem;
    margin-left:0.5rem;
}
.repeating-invoice-create-container {
    display:flex;
    align-items: center;
}
`;

export interface IInvoicesMethods {
    save:()=>Promise<boolean>
}


const Invoices = React.forwardRef<IInvoicesMethods, IProps>((props:IProps, ref) => {
    
    
    const ticker = props.ticker;
    useImperativeHandle(ref, ():IInvoicesMethods => ({

        async save() {
            const request:SaveInvoiceDataRequest = {
                Ticker: ticker,
                XeroRepeatingInvoiceId: xeroRepeatingInvoiceId||'',
                Invoices: invoices
            }
            await api().saveStockInvoiceData(request);
            return true;  
        }
    
      }));
    
    const [ xeroRepeatingInvoiceId, setXeroRepeatingInvoiceId ] = useState<string>();
    const [ xeroContactId, setXeroContactId ] = useState<string>();
    const [ xeroReference, setXeroReference ] = useState<string>();
    const [ invoices, setInvoices ] = useState<Invoice[]>([]);
    const [ serviceOrder, setServiceOrder ] = useState<ServiceOrder>();
    const [ creatingContact, setCreatingContact ] = useState<boolean>(false);
    const [ creatingRepeatingInvoice, setCreatingRepeatingInvoice ] = useState<boolean>(false);

    const [ repeatingInvoices, setRepeatingInvoices ] = useState<AdminInvoiceResultItem[]>([]);
    const [ repeatingInvoiceAmount, setRepeatingInvoiceAmount ] = useState<number>(1800);
    
    
    useAsyncEffect(async ()=> {
        const result = await api().getInvoices(ticker);   
        setResult(result);     
        
        const order = await api().getServiceOrder(ticker);   
        setServiceOrder(order);
        if(order){
            setRepeatingInvoiceAmount(order.option.price)
        }
        
    }, []);

    const setResult = (result:AdminInvoiceResult) => {
        setRepeatingInvoices(result.repeatingInvoices)
        setXeroRepeatingInvoiceId(result.xeroRepeatingInvoiceId);
        setInvoices(result.invoices);
        setXeroContactId(result.xeroContactId);
        setXeroReference(result.xeroReference);
    }

    const createContact = async () => {
        setCreatingContact(true)
        let result = await api().createContact(serviceOrder!.id);
        setXeroContactId(result.xeroContactId);
        setCreatingContact(false)
        return true;
    }

    const createRepeatingInvoice = async () => {
        setCreatingRepeatingInvoice(true)
        let result = await api().createXeroRepeatingInvoice(props.stockId, repeatingInvoiceAmount);
        if(result.success){
            setRepeatingInvoices( [ ...repeatingInvoices, result.repeatingInvoice ]);
            setXeroRepeatingInvoiceId(result.repeatingInvoice.id);        
        }else{
            alert(`Unable to create repeating invoice: ${result.errorMessage}`);
        }
        
        setCreatingRepeatingInvoice(false)
        return true;
    }


    return <Style className="input-group" >

               <div className="form-item" >
                    <label htmlFor="">Repeating Invoice
                    { xeroRepeatingInvoiceId  && <a target="_blank"
                href={getRepeatingInvoiceUrl(xeroRepeatingInvoiceId)}
                rel="noopener noreferrer"
                className='repeating-invoice-link'
            >     [Open in Xero] </a> }

{ xeroContactId  && <a target="_blank"
                href={`https://go.xero.com/Contacts/View/${xeroContactId}`}
                rel="noopener noreferrer"
                className='repeating-invoice-contact'
            > [Xero Contact]</a> }

                     </label>
                    <select 
                        value={xeroRepeatingInvoiceId || ''} 
                        disabled={repeatingInvoices.length===0}
                        name='xeroRepeatingInvoiceId'
                        onChange={(e: any) => setXeroRepeatingInvoiceId(e.target.value)}
                    >
                        <option  value=""></option>
                        {repeatingInvoices.map((x, i) => <option key={i} value={x.id}>{x.text}</option>)}

                    </select>
                </div>



                { !invoices.length && <p>No Invoices</p>}
                { !!invoices.length && <div className="invoices">
                    <p>Invoices - to add a new invoice use <Link to="/xero-page">Xero Integration</Link></p>                    
                    <ul>
                    { invoices.map((x,i)=> <li key={i}>{invoiceStringGetter(x)}</li>)}
                    </ul>
                    </div>}

                   
                    { xeroContactId && !xeroRepeatingInvoiceId && <div className='repeating-invoice-create-container'>
                    <FormTextItem label="Repeating Invoice Amount (ex GST)" value={repeatingInvoiceAmount}  onChange={(e)=> { setRepeatingInvoiceAmount(e.target.value)}} />
                        <div style={{'marginLeft' : '1rem'}}>
                        <AnimatedSaveButton onClick={createRepeatingInvoice} startingText="Create Repeating Invoice In Xero" progressText="Creating" completedText="Created" disabled={creatingRepeatingInvoice} />

                        </div>
                    </div> }
            
                { serviceOrder && <div>
                
                { !xeroContactId && <AnimatedSaveButton onClick={createContact} startingText="Create Contact In Xero" progressText="Creating" completedText="Created" disabled={creatingContact} /> }

                
                    
                    <div style={{width:'150px'}}>
                    <FormTextItem label="Reference" value={xeroReference||''} disabled={true} onChange={(e)=> { setXeroReference(e.target.value)}} />
                    </div>
                 

               

                
                

                <h3>Service Order</h3>

                <ServiceOrderItem                     
                    order={serviceOrder} 
                    ticker={serviceOrder.ticker}                    
                    />

            </div>
            }
                
                                           
                
            </Style>    
});

export default Invoices;

