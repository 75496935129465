import React from 'react';
import StockForm from './stock/StockForm';
import { connect } from 'react-redux';
import Stock from '../model/Stock';
import { startSaveStock } from '../actions/CurrentStock';
import SaveStockResult from '../model/SaveStockResult';
import { getCombinedTicker } from '../api/api';

interface IProps {
    startSaveStock: (stock:Stock) => Promise<SaveStockResult>;
}

export class AddStockPage extends React.Component<IProps> {
    onSubmit = async (stock:Stock) => {
        let result = await this.props.startSaveStock(stock);
        if(result.success){
            (this.props as any).history.push(`/stocks/${getCombinedTicker(stock)}`);
        }
        
        return Promise.resolve(result);
    }
    render(){
        return (
            <div className="padding">
                <StockForm 
                onSubmit={this.onSubmit} 
                />
            </div>
        )
    }
}

const mapDispatchToProps = (dispatch:any) => ({
    startSaveStock : (stock:Stock) => dispatch(startSaveStock(stock))
})

export default connect(null, mapDispatchToProps)(AddStockPage);