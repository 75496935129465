import Stock from '../model/DashboardStock'

export default function get(arr:Stock[], filters : { text:string, billable:string }) {
    
    let stocks = [ ...arr ]
    const filtersText = (filters.text || '').toLowerCase();
    if(filtersText){
        stocks = stocks.filter(x=>{
            const textMatch = !filters.text
            || x.ticker.toLowerCase().includes(filtersText)
            || x.exchange.toLowerCase().includes(filtersText)
            || (x.websiteUrl != null && x.websiteUrl.toLowerCase().includes(filtersText))
            || (x.companyName != null && x.companyName.toLowerCase().includes(filtersText))
            ;
            
            return textMatch;
        })
    }
    const type = filters.billable;
    const archived = type === 'Archived';
    stocks = stocks.filter(x=> x.archived === archived)
    
    if(type && !archived){
        const unpaid = type === 'Billable - Unpaid';
        const onlyBillable = type === 'Billable' || unpaid;
        stocks = stocks.filter(x=> (onlyBillable && !!x.repeatingInvoice) || (!onlyBillable && !x.repeatingInvoice))
        if(unpaid){
            stocks = stocks.filter(x=> !x.invoice || x.invoice.status !== "PAID")
        }
    }
    
    return stocks;
    
}