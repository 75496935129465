import Stock from "../model/Stock";


const reducer = (state:Stock = null as any, action:{ type:string, value:any}) => {
  
    switch(action.type){
    case 'SET_STOCK':
      return action.value;
      case 'RESET_STOCK':
        return null;
    default:
      return state;
  }
}

export default reducer;