import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';

import dashboardStocksReducer from '../reducers/dashboard-stocks'
import filtersReducer from '../reducers/filters'
import loadingReducer from '../reducers/loading'
import currentStockReducer from '../reducers/CurrentStock'
import userReducer from '../reducers/userReducer'

const composeEnhancers = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export default function get() {
    const store = createStore(
        combineReducers({
            dashboard_stocks: dashboardStocksReducer,
            filters: filtersReducer,
            loading: loadingReducer,
            currentStock: currentStockReducer,
            user: userReducer,
        }),
        composeEnhancers(applyMiddleware(thunk))
        //(window as any).__REDUX_DEVTOOLS_EXTENSION__ && (window as any).__REDUX_DEVTOOLS_EXTENSION__()
    );

    return store;
}

