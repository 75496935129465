import React from 'react';
import StockForm from './stock/StockForm';
import { connect } from 'react-redux';
import { startSetStock, resetStock, startSaveStock } from '../actions/CurrentStock'
import Stock from '../model/Stock';
import SaveStockResult from '../model/SaveStockResult';

interface IProps {
    startSetStock?: (ticker:string) => void;
    startSaveStock?: (stock:Stock) => Promise<SaveStockResult>;
    resetStock?: () => void;
    stock?:Stock;
    match?: { params: { id: string, tab:string }};
}

export class EditStockPage extends React.Component<IProps> {
    
    componentDidMount() {
        this.props.startSetStock!(this.props.match!.params.id);
    }
   
    componentWillUnmount(){
        this.props.resetStock!(); 
    }


    render() {
        
        return (
            <div className="padding">
                {this.props.stock && <StockForm 
                stock={this.props.stock} 
                tab={this.props.match!.params.tab}
                onSubmit={this.props.startSaveStock!} 
                />}
            </div>
        )
    }
}

const mapStateToProps = (state:any) => {
    
    let obj = {
        stock: state.currentStock
    }
    return obj;
}

const mapDispatchToProps = (dispatch:any) => ({
    startSetStock : (ticker:string) => dispatch(startSetStock(ticker)),
    resetStock : () => dispatch(resetStock()),
    startSaveStock : (stock:Stock) => dispatch(startSaveStock(stock))
})

const areStatesEqual = (next: any, prev: any) => {    
    let result = prev.currentStock === next.currentStock;           
    return result;
  }
export default connect(mapStateToProps, mapDispatchToProps, null, { areStatesEqual})(EditStockPage);