import React from 'react';
import styled from 'styled-components'

import ServiceOrder from '../../model/ServiceOrder';
import moment from 'moment';
import AnimatedSaveButton from '../AnimatedSaveButton';
import FormTextItem from '../form/FormTextItem';

const Style = styled.div`

display: flex;
    align-items: start;

    & > * {
        margin: 3.5rem 0.5rem 0 0;
    }
`;

interface IProps{
    order:ServiceOrder
    create?:(order:ServiceOrder)=>Promise<boolean>
    orderFieldChanged? :(fieldName:string, value:string, id:string)=>void
    ticker:string
    reference?:string
}

const ServiceOrderItem:React.FC<IProps> = (props:IProps) => {
    
    const { order, create, orderFieldChanged } = props;

    return <Style>


        <span>{moment(order.timestamp).format('DD MMM YY')}</span>

        <div>
            <ul>
                <li>Company Name: {order.companyName}</li>
                <li>
                    { !order.isCreated && <FormTextItem label="Ticker/ASX Code" value={props.ticker} onChange={(e) =>  orderFieldChanged!('ticker', e.target.value, order.id)}  />} 
                    { order.isCreated && <span>Ticker/ASX Code: {order.ticker}</span>}
                </li>
                <li>
                    { !order.isCreated && <FormTextItem label="Reference" value={props.reference||''} onChange={(e) =>  orderFieldChanged!('reference', e.target.value, order.id)}  />} 
                    { order.isCreated && <span>Reference: {order.ticker}</span>}
                </li>
                <li>Service: {order.option.name} ${order.option.price.toLocaleString()} {order.option.lineItems.map((x,index)=><p key={index}>{x}</p>)} </li>
                <li>Authorised By: {order.authorisedBy}</li>
                <li>website: {order.website}</li>
                <li>address: {order.address}</li>
                <li>contactName: {order.contactName}</li>
                <li>contactPhone: {order.contactPhone}</li>
                <li>contactEmail: {order.contactEmail}</li>
                <li>abn: {order.abn}</li>
               
            </ul>

            <span>
                {!order.isCreated && <div>
                    <AnimatedSaveButton onClick={() => create!(order)}
                        startingText="Create Service"
                        progressText="Creating"
                        completedText="Created"
                    />
                </div>
                }
            </span>

        </div>

    </Style>
}

export default ServiceOrderItem;