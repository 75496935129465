import React from 'react';
import Stock from '../../model/DashboardStock' 
import { Link } from 'react-router-dom';
import { getWebsiteUrl, getDisplayUrl } from './DashboardHelpers';
import * as Exchange from '../../model/Exchange'

interface IProps {
  stock:Stock
}



const DashboardItem:React.FC<IProps> = (props:IProps)=> {  
  const exchangeStr = props.stock.exchange !== Exchange.ASX ? `:${props.stock.exchange}`:''
  return <div className="dashboard-row">
  <Link to={`/stocks/${props.stock.ticker}:${props.stock.exchange}`} className="dashboard-row__ticker">
    {`${props.stock.ticker}${exchangeStr}`}
  </Link>



  <a target="_blank"
    href={getWebsiteUrl(props.stock)}
    rel="noopener noreferrer"
    className="dashboard-row__website"
  >{getDisplayUrl(props.stock.websiteUrl)}</a>
</div>
}

export default DashboardItem
