import React, { useState } from 'react';
import api from '../api/api';
import styled from 'styled-components';
import { FaSpinner } from 'react-icons/fa';
import { useLocation } from 'react-router-dom';
// import { history } from '../index';

const LoginPageWrapper = styled.div`
    
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
   
    .login-box {
        width:400px;
        display: flex;
        flex-direction:column;
    }
    .login-box > * {
        margin-top:10px;        
    }
    .logo-container {
        display:flex;
        justify-content: center;
        padding: 2rem;
    }
    .error {
        font-weight: bold;
    }
`;

const LoginPage = () => {
 

    const [ username, setUsername ] = useState('');
    const [ password, setPassword ] = useState('');
    const [ error, setError ] = useState('');
    const [ loading, setLoading ] = useState(false);
    const location = useLocation();
    const { from } = location.state || { from: { pathname: "/" } };
    
    const login = () =>{
        setError('')
        setLoading(true)
        api().login(username, password).then(result=>{
            window.location.href = from.pathname;
            // history.push(from.pathname)
        }).catch(err => {            
            setError('Invalid username/password')
            setLoading(false)
        });
    }

    return <LoginPageWrapper>
        <div className="login-box">
            <div className="logo-container investi-dark-blue"><img src="/images/logo.svg" alt="logo" className="front-page-logo " /></div>
            <input type="text" placeholder="username" onChange={(e: any) => setUsername(e.target.value)} disabled={loading} />
            <input type="password" placeholder="password" onChange={(e: any) => setPassword(e.target.value)} disabled={loading} />
            {error && <p className="error">{error}</p>}
            <button onClick={login} disabled={loading}>Login &nbsp;
            { loading && <FaSpinner className="fa-spin" size={16} color="white" />}
            </button>
        </div>
        </LoginPageWrapper>
    
}

export default LoginPage;
