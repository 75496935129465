import moment from 'moment';

const filtersReducerDefaultState = {
    text: '',
    billable: '',
    sortBy: 'date',
    startDate: moment().startOf('month'),
    endDate: moment().endOf('month')
};
const filtersReducer = (state = filtersReducerDefaultState, action:any) => {
    //console.log('filters action', action.type)
    if(action.type==='SET_TEXT_FILTER'){
        return {
            ...state,
            text: action.text,            
        };
    }else if(action.type==='SET_BILLABLE_FILTER'){
        return {
            ...state,
            billable: action.billable,            
        };
    }
    return state;
}

export default filtersReducer;