import React from 'react'
import styled from 'styled-components';


import { ChangeRow } from '../../model/GenericHistoryViewModel';


interface IProps {
    row: ChangeRow
}

const Style = styled.tr`

`;



const ChangeRowComponent: React.FC<IProps> = (props: IProps) => {

    const { fieldName, originalValue, newValue } = props.row;

    return <Style >
        <td>{fieldName}</td>
        <td>{originalValue}</td>
        <td>{newValue}</td>
    </Style>
}

export default ChangeRowComponent;