import React, { useRef, useState } from 'react';
import Stock from '../../model/Stock';
import FormTextItem from '../form/FormTextItem'
import FormSwitchItem from '../form/FormSwitchItem';
import ConfigNotificationSettings from '../form/ConfigNotificationSettings';
import TextInput from '../form/TextInput';
import api, { combinedTicker } from '../../api/api';
import { ChangeTickerRequest } from '../../model/ChangeTickerRequest';
import { getBaseAddress, to } from '../../util/util';
import { history } from '../../index';
import { ArchiveStockRequest } from '../../model/ArchiveStockRequest';
import * as Exchange from '../../model/Exchange'
import useAsyncEffect from 'use-async-effect';
import FormTextArea from '../form/FormTextArea';

interface IProps {
    stock:Stock;
    onChange:(name:string, value:string|boolean)=>void;
}


const Config:React.FC<IProps> = (props:IProps) => {

    const [ editingTicker, setEditingTicker ] = useState(false);
    const [ exchanges, setExchanges ] = useState<string[]>([])
    const originalTicker = useRef(props.stock.ticker);
    let baseAddress =  getBaseAddress();
   
    const onChangeCheckbox = (name:string, value:string|boolean) : void => {                
        
        props.onChange(name, value)
    }
    
    const isNew = !props.stock.id;

    useAsyncEffect(async(isMounted)=>{
        if(isNew){
            setExchanges(await api().getExchanges());
        }else{
            setExchanges( [ props.stock.exchange ]);
        }
    }, [])

    const onChange = (e:React.ChangeEvent<HTMLInputElement>) => {                
        props.onChange(e.target.name, e.target.value)
    }

    const insertAutoSendFilter = () => {
        props.onChange('autoSendFilter', '^.*appendix.*(3b||3y).*$')        
      }


      const getApiKey = async () => {
        if (props.stock.apiKey) {
            if (!window.confirm('This stock already has an API key\r\n\r\nGenerating a new one will break existing applications that use this key.\r\n\r\nAre you sure?')) {
                return;
            }
        }
        const apiKey = await api().getNewApiKey();
        props.onChange('apiKey', apiKey)
    }

    const onTickerChange = (e:any) => {
        e.target.value = e.target.value.toUpperCase();
        onChange(e)
    }
    const changeTickerClicked = async (e:any) => {
        if(editingTicker){
            const newTicker = props.stock.ticker.trim();
            if(newTicker === originalTicker.current){
                setEditingTicker(false);
                return;
            }
            if(window.confirm(`You are changing ticker from ${originalTicker.current} to ${newTicker}. Are you sure?`)){
                let request:ChangeTickerRequest = { 
                    exchange: props.stock.exchange,
                    ticker: originalTicker.current,
                    newTicker: props.stock.ticker,
                }                

                const [err, result] = await to(api().changeTicker(request))                
                if(err || result.errorMessage){
                    alert(err || result.errorMessage)
                    setEditingTicker(false);                                    
                }else{
                    history.push(`/stocks/${combinedTicker(newTicker, props.stock.exchange)}`);
                    window.location.reload();
                }
            }else{
                props.onChange('ticker', originalTicker.current)
                setEditingTicker(false);
            }
            
            
        }else{
            setEditingTicker(true);
        }
        
    }

    const archiveStockClicked = async (e:any) => {
        if(!window.confirm(`You are archiving ${props.stock.ticker}:${props.stock.exchange}.  Are you sure?`)){
            return;
        }
        let request:ArchiveStockRequest = { 
            exchange: props.stock.exchange,
            ticker: props.stock.ticker,
        }  
        const [err, result] = await to(api().archiveStock(request))                
        if(err || result.errorMessage){
            alert(err || result.errorMessage)
                                           
        }else{
            
            window.location.reload();
        }
    }
    
    const { stock } = props;
    
    
     let exchangeLink = '';
     if(stock.exchange === Exchange.ASX){
        exchangeLink = `https://www2.asx.com.au/markets/company/${stock.ticker}`;
     }else if(stock.exchange === Exchange.LSE){
        exchangeLink = `https://www.londonstockexchange.com/stock/${stock.ticker}/${stock.companyName}/company-page`;
     }else if(stock.exchange === Exchange.FSE){
        exchangeLink = `https://www.boerse-frankfurt.de/equity/${stock.companyName}`;
     }else if(stock.exchange === Exchange.OTC){
        exchangeLink = `https://www.otcmarkets.com/stock/${stock.ticker}`;
     }
    return <div className="input-group">
               
                {/* <FormTextItem label="Ticker" value={stock.ticker!} onChange={onTickerChange} disabled={!!props.stock.id}/> */}
                <div className="form-item">
                    <div className="config__ticker-container">
                        <div>
                            <div>
                                <label htmlFor="">Ticker</label>
                            </div>
                            <TextInput value={stock.ticker!} name="ticker" onChange={onTickerChange} disabled={!!props.stock.id && !editingTicker} />
                        </div>
                        <div className="config__exchange">
                            <div>
                                <label htmlFor="">Exchange</label>
                            </div>
                            <select 
                            value={stock.exchange!} 
                            name="exchange" 
                            onChange={(e) => { props.onChange(e.target.name, e.target.value) }} 
                            disabled={!isNew} >
                                
                                { exchanges.map((exchange, index) => <option key={index}>{exchange}</option>)}
                                                                
                            </select>
                            
                    {exchangeLink && <a target="_blank"
                        href={exchangeLink}
                        rel="noopener noreferrer"
                        className="dashboard-row__exchange-link"
                    >[View on Exchange]</a>
                    }
                           
                        </div>

                        { (stock.exchange === Exchange.LSE || stock.exchange === Exchange.FSE) && 
                        <div style={{marginLeft: '1rem'}}>
                            <div >
                                <label htmlFor="">ISIN</label>
                            </div>
                            <TextInput value={stock.isin!} name="isin" onChange={(e: any) => { props.onChange(e.target.name, e.target.value) }} />
                        </div>
                        }
                        

                        {!!props.stock.id && 
                        <>
                        <button 
                            className={"config__button " + (editingTicker ? 'config__changeTickerButton__confirming' : '')}
                            onClick={(e)=> { changeTickerClicked(e)}}
                         > { editingTicker ? 'Confirm Change Ticker' : 'Change Ticker'}
                         </button> 

                         <button    
                            className="config__button config__button__archive"                      
                            onClick={(e)=> { archiveStockClicked(e)}}
                         > { props.stock.archived ? 'Unarchive' : 'Archive'}
                         </button> 
                        </>
                         }
                        
                         
                        
                    </div>                    
                </div>

                <FormTextItem label="Company Name" value={stock.companyName!} onChange={onChange}/>

                <FormTextItem label="Billing Date" value={stock.billingDate!} onChange={onChange}/>
                                           
               <div className="config__poll-container">
               <FormSwitchItem label="Poll" name="pollAsx" value={stock.pollAsx} onChange={onChangeCheckbox}/>
               <FormSwitchItem label="Subscription Expired" name="subscriptionExpired" value={stock.subscriptionExpired} onChange={onChangeCheckbox}/>
               </div>
                

                <ConfigNotificationSettings 
                emailName="sendPriceSensitiveAnnouncements" 
                mailchimpName="autoSendAnnouncementEmail" 
                emailIsChecked={stock.sendPriceSensitiveAnnouncements!} 
                mailchimpIsChecked={stock.autoSendAnnouncementEmail!} 
                onChange={onChangeCheckbox} >
                    Send Price Sensitive
                </ConfigNotificationSettings>

                <ConfigNotificationSettings 
                emailName="sendNonPriceSensitiveAnnouncements" 
                mailchimpName="autoSendNonPriceSensitiveAnnouncements" 
                emailIsChecked={stock.sendNonPriceSensitiveAnnouncements!} 
                mailchimpIsChecked={stock.autoSendNonPriceSensitiveAnnouncements!} 
                onChange={onChangeCheckbox} >
                    Send <em>Non</em> Price Sensitive
                </ConfigNotificationSettings>
                
                
                <div className="form-item">
                    <div>
                    <label htmlFor="">Auto Send Filter</label>
                    <small style={{marginLeft:'10px'}}>Announcements matching this filter will NOT be auto sent <span className="link" onClick={insertAutoSendFilter}>Click here</span> to insert standard filter</small>
                    </div>
                    <TextInput value={stock.autoSendFilter} name='autoSendFilter' onChange={onChange} />
                </div>

                
                <FormTextItem label="Poll Start Year" name='announcementStartYear' value={stock.announcementStartYear!} onChange={onChange}/>
                <FormTextItem label="Confirmation Emails" helperText="Recipients will receive the Mailchimp 'Send Now' email"  name='confirmationEmail' value={stock.confirmationEmail!} onChange={onChange}/>
                <FormTextItem label="Website Url" name='websiteUrl' value={stock.websiteUrl!} onChange={onChange}>
                {stock.websiteUrl &&
                        <a target="_blank"
                            href={stock.websiteUrl}
                            rel="noopener noreferrer"
                            className="dashboard-row__website"
                        >[ Open ]</a>
                    }
                </FormTextItem>
               
                
                <div className="form-item">
                    <div className="spaceBetween">
                        <label htmlFor="">API Key</label>
                        <button className="button-sm" onClick={getApiKey}>Generate</button>
                    </div>
                    <TextInput value={stock.apiKey} name='apiKey' onChange={onChange} />
                    {stock.apiKey &&
                        <a target="_blank"
                            href={`${baseAddress}/sample.html?apiKey=${stock.apiKey}`}
                            rel="noopener noreferrer"
                            className="dashboard-row__website"
                        >[Test API]</a>
                    }
                    
                </div>                
                
                
                <div className="blue-box">
                    <FormTextItem label="Announcement Notification" helperText="Recipients will receive a notification email when an announcement is released."  name='announcementNotificationEmails' value={stock.announcementNotificationEmails!} onChange={onChange}  />
                </div>
                <FormTextItem label="Contact Emails" helperText="The non-billing contact at the Company"  name='contactEmail' value={stock.contactEmail!} onChange={onChange}/>
                
                <FormTextItem label="Moderator Emails" helperText="Recipients will receive comments"  name='moderatorEmail' value={stock.moderatorEmail!} onChange={onChange}/>
                <FormTextItem label="Company Logo Url"   name='companyLogoUrl' value={stock.companyLogoUrl!} onChange={onChange}/>
                <FormTextArea label="Announcement Comment Template" placeholder='Paste template here' rows={10} name="announcementCommentTemplate" value={stock.announcementCommentTemplate||''} onChange={onChange} className='config__announcement-comment-template' />

                
            </div>    
}

export default Config;
