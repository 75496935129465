import React from 'react'
import { AdminReportView } from '../../model/AdminReportsView';
import styled from 'styled-components';
import moment from 'moment'

interface IProps {
    item:AdminReportView
}

const Root = styled.div`
display:flex;
justify-content: space-between;
margin: 1rem 0;
`
const dateFormat = 'D MMM YYYY h:mm A';
const ReportItem:React.FC<IProps> = (props:IProps)=>{
    
    const { created, guid, startDate, endDate } = props.item;
    
    return <Root>
        <a href={`/report/${guid}`} target="_blank" rel="noreferrer">
            {moment(created).format(dateFormat)}</a>

            <span>Range: {startDate} - {endDate}</span>
    </Root>
}

export default ReportItem;