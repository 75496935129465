import React from 'react'
import * as Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { ReportViewModel } from '../../model/ReportViewModel'
import styled from 'styled-components';
import { Tooltip, TooltipFormatterContextObject } from 'highcharts';

interface IProps {
    view:ReportViewModel
}


const Section = styled.section`

em {
    font-weight:bold;
}

`;

const ComparativeTradingActivityPage:React.FC<IProps> = (props:IProps)=>{
    
    
    const { view } = props;
    const data:any = [{
        name: view.ticker,
        data: view.comparativeTradingActivity.items.map(x=>Math.round(x.target)),
        // pointWidth:8
        
        
    }, {
        name: 'Peers',
        data: view.comparativeTradingActivity.items.map(x=>Math.round(x.peers)),
        // pointWidth:8
        
    }];
    const options: Highcharts.Options = {
        chart: {
            type: 'bar',  
            events: {
                load: function() {
                    let categoryHeight = 25;
                    this.update({
                      chart: {
                        height: categoryHeight * this.pointCount + (this.chartHeight - this.plotHeight)
                      }
                    })
                  }
            }          
        },
        
        title: {
            text: ''
        },
        subtitle: {
            text: `Period: (${view.startDate} - ${view.endDate})`
        },
        tooltip: {
            formatter: function(context:TooltipFormatterContextObject, tooltip: Tooltip) {
                // console.log('series', this.series)
                const item = view.comparativeTradingActivity.items[this.point.index];
                console.log('index', item);
                // return 'The value for <b>' + this.x + '</b> is <b>' + this.y + '</b>, in series '+ this.series.name;

                const meta = this.series.name === view.ticker ? [ item.meta[0] ] : item.meta.slice(1);
                console.log('meta', meta)
                return `<div>${this.y}% traded via ${this.x} </div>
                ${meta.map(x=> `<br/> <b>${x.ticker}</b>: ${x.value.toLocaleString()}`)}
                `
            }
        },
        xAxis: {
            categories: view.comparativeTradingActivity.items.map(x=>x.name),
            title: {
                text: ''
            }           
        },
        yAxis: {
            min: 0,
            title: {
                text: '',                
            },
            labels: {
                format: '{value}%',
                overflow: 'justify'
            }
        },
        
        plotOptions: {
            bar: {
                pointWidth: 15,                
            }
        },
        legend: {
            layout: 'vertical',
            align: 'right',
            verticalAlign: 'top',
            x: -40,
            y: 80,
            floating: true,
            borderWidth: 1,
            backgroundColor:
                Highcharts.defaultOptions.legend!.backgroundColor || '#FFFFFF',
            shadow: true
        },
        credits: {
            enabled: false
        },
        series: data
    }
    
    return <Section>
        <h2>Comparative Trading Activity</h2>
        <p>Total Value Traded: ({view.ticker}): <em>${view.comparativeTradingActivity.targetValue.toLocaleString()}</em> </p>
        <p>Total Value Traded: (Peers, {view.peers.join(',')}): <em>${view.comparativeTradingActivity.totalPeerValue.toLocaleString()}</em> </p>

        <HighchartsReact
            highcharts={Highcharts}
            options={options}
        />

    </Section>
    
    
    
}

export default ComparativeTradingActivityPage;