import Stock from "../../model/DashboardStock";

export const getWebsiteUrl = (stock:Stock): string | undefined => {
    let url = stock.websiteUrl || '';
    if (url && !url.startsWith('http')) {
      url = `http://${url}`;
    }
    return url;
  }
  
export const getDisplayUrl = (websiteUrl:string | null) => {
    if(!websiteUrl)
      return '';
    return websiteUrl.replace('https://', '').replace('http://', '').replace(/\/\s*$/, ""); //remove trailing /
  }