import { User } from "./User"

export const InvoiceTotal:string = "InvoiceTotal"
export const Admin:string = "Admin"
export const RunReports:string = "RunReports"
export const EditUsers:string = "EditUsers"
export const ReplyToComment:string = "ReplyToComment"
export const EditAnnouncements:string = "EditAnnouncements"
export const EditMailchimp:string = "EditMailchimp"
export const SendMailchimp:string = "SendMailchimp"
export const TestSendAnnouncement:string = "TestSendAnnouncement"
export const Invoicing:string = "Invoicing"


export const IsAdminOrHasRole = (user:User, stockId:string, role:string)=>{
    return HasAdminRole(user) || user.stockRoles[stockId].includes(role);
}
export const HasAdminRole = (user:User)=>{
    return HasRole(user, Admin);
}

export const HasRole = (user:User, role:string)=>{
    return user.roles.hasOwnProperty(role)
}
export const HasStockRole = (user:User, role:string)=>{
    for(const stockId in user.stockRoles){
        if(user.stockRoles[stockId].includes(role)){
            return true;
        }
    }
}
