import React from 'react'
import styled from 'styled-components';
import { BrokerActivityViewModel } from '../../model/BrokerActivityViewModel';
import BrokerActivitySingle from './BrokerActivitySingle';

interface IProps {
    views:BrokerActivityViewModel[]
    startDate:string
    endDate:string
    title?:string
}


const Section = styled.section`



`;

const BrokerActivity:React.FC<IProps> = (props:IProps)=>{
           

    return <Section>
        <h3>{props.title || "Broker Activity"}</h3>
        <p>Period: ({props.startDate} - {props.endDate})</p>
        { props.views.map((x,index)=> <BrokerActivitySingle view={x} showTable={props.views.length===1} key={index}/>)}



    </Section>
    
    
    
}

export default BrokerActivity;