import React from 'react'
import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import { ReportViewModel } from '../../model/ReportViewModel';

interface IProps {
    view:ReportViewModel
}

const getChartData = (data:any, startDate:any)=> {
    let series1 = [];
    for (let i = 0; i < data.length; i++) {
        let jsDate = new Date(data[i].date.substring(0, 10));
        if (jsDate.getTime() >= startDate.getTime()) {
            let dateMs = Math.round(jsDate.getTime());
            series1.push([dateMs, data[i].close]);
        }
    }
    return series1;
}
const getChartSeries = (stock:any, startDate:any, i:number) => {
    let options = {
        name: stock.ticker,
        data: getChartData(stock.stockData, startDate),
        type: 'line',
        gapSize: 5,
        showInLegend: true,
        lineWidth: 1,
        id: stock.ticker
    };
    if (i === 0) {
        options.lineWidth = 4;
        //options.color = 'darkblue';
    }
    return options;
}

const getAnnouncementSeries = (stock:any, startDate:any, i:number) => {
    let announcementChartData = [];
    for (let announcement of stock.announcements||[]) {
        if (announcement.priceSensitive) {
            let jsDate = new Date(announcement.date.substring(0, 10));
            if (jsDate.getTime() > startDate.getTime()) {
                let dateMs = Math.round(jsDate.getTime());
                let existing = announcementChartData.find(d => d.x === dateMs);
                if (existing) {
                    existing.text += '<br/>' + announcement.headline;
                }
                else {
                    announcementChartData.push({
                        date: jsDate,
                        x: dateMs,
                        title: '',
                        style: {
                            color: 'rgba(0,0,0,0)'
                        },
                        text: stock.ticker + " : " + announcement.headline
                    });
                }
            }
        }
    }
    let color = (Highcharts as any).getOptions().colors[i];
    announcementChartData.sort((a, b) => a.x - b.x);
    let options = {
        type: 'flags',
        data: announcementChartData,
        onSeries: stock.ticker,
        width: 4,
        height: 4,
        color: color,
        fillColor: color,
        //shape: "url(dot.png)",
        shape: "circlepin",
    };
    return options;
}

const PriceComparison:React.FC<IProps> = (props:IProps)=>{
    const { view } = props;
    let seriesOptions:any = [];
    
    
    const data = view.priceComparison.stocks;
    let startDate:Date;
    if(data[0].stockData.length){
        startDate = new Date(data[0].stockData[0].date)

        for (let i = 0; i < data.length; i++) {
            seriesOptions.push(getChartSeries(data[i], startDate, i));
        }
        for (let i = 0; i < data.length; i++) {
            let options = getAnnouncementSeries(data[i], startDate, i);
            seriesOptions.push(options);
        }
    }

    
    const options: Highcharts.Options = {
        rangeSelector: {
            enabled: false
        },
        navigator: {
            enabled: false
        },
        title: {
            text: 'Price & Performance (Rebased)',
        },
        scrollbar: {
            enabled: false
        },
        yAxis: {
            labels: {
                formatter: function () {
                    return (this.value > 0 ? ' + ' : '') + this.value + '%';
                }
            },
            plotLines: [{
                    value: 0,
                    width: 2,
                    color: 'silver'
                }]
        },
        plotOptions: {
            series: {
                compare: 'percent',
            },
            flags: {
                showInLegend: false,
                style: {
                    color: 'white'
                },
                states: {
                    hover: {
                        fillColor: '#395C84' // darker
                    }
                },
            }
        },
        
        series: seriesOptions,
        legend: {
            layout: 'vertical',
            align: 'left',
            verticalAlign: 'top',
            floating: true,
            borderWidth: 1,
            
            enabled: true
        },
    }
    
    return <section>
        <h3>Price</h3>
        <p>Period: ({view.startDate} - {view.endDate})</p>
        <HighchartsReact
            highcharts={Highcharts}
            constructorType={'stockChart'}
            options={options}
        />
    </section>
}

export default PriceComparison;