import React from 'react'
import styled from 'styled-components';
import { ActivitySummaryViewModel } from '../../model/ActivitySummaryViewModel';

interface IProps {
    view:ActivitySummaryViewModel
}


const Section = styled.section`



em{
    font-weight: bold;
    font-style: normal;
}

.summary {
    border: 1px solid grey;
background-color: lightgray;
padding: 0 1rem;
}

ul {
  list-style-type: none;
}

table{
    margin-top: 0;
    margin-left: 1rem;
    margin-bottom: 2rem;
    width: inherit;
    font-family: inherit;
    font-size: inherit;
}

.peer-performance-percentage{
    margin-left: 1rem;
    font-weight: bold;
}
.percentage-up {
    color:green;
}
.percentage-down {
    color:red;
}

`;

const ActivitySummary:React.FC<IProps> = (props:IProps)=>{
           
    const { view } = props;
    const getPercentageStyle = (val:number)=>{
        if(val > 0){
            return 'percentage-up'
        }else if(val < 0){
            return 'percentage-down'
        }
        return '';
    }
    
    return <Section>
        <h3>Activity Summary</h3>
       
       <div className="summary">
       <p>For <em>{view.period}</em>, <em>{view.companyName}</em> traded <em>{view.sharesTraded}</em> shares, valued at <em>{view.sharesValue}</em>. 
       During the reporting period, the share price varied from a low of <em>${view.shareLowPrice}</em> on {view.shareLowDate} to a high of <em>${view.shareHighPrice}</em> on <em>{view.shareHighDate}</em>.</p>

        <p>
        The largest trading/clearing house for {view.period} was <em>{view.broker1Name}</em> with <em>{view.broker1Percentage}</em>% of the trades, followed by <em>{view.broker2Name}</em> with <em> {view.broker2Percentage}</em>%. 
         The largest net buyer of <em>{view.companyName}</em> shares during {view.period} was <em>{view.largestBuyerName}</em> accumulating <em>{view.largestBuyerShares}</em> shares. 
        The largest net seller of  <em>{view.companyName}</em> shares during {view.period} was <em>{view.largestSellerName}</em> shedding <em>{view.largestSellerShares}</em> shares.
        </p>

        <p>

        Trading in <em>{view.companyName}</em> shares for {view.period} was {view.sharesPreviousPeriodDifference > 0 ? 'higher':'lower'} than {view.previousPeriod}, with {Math.abs(view.sharesPreviousPeriodDifference).toLocaleString()}  {view.sharesPreviousPeriodDifference > 0 ? 'more':'fewer'} shares traded this month. 
        There were <em>{view.numTrades.toLocaleString()}</em> trades averaging <em>${view.avgTradeSize.toLocaleString()}</em> for {view.period}, compared to <em>{view.numTradesPrior.toLocaleString()}</em> trades averaging <em>${view.avgTradeSizePrior.toLocaleString()}</em> for {view.previousPeriod}.

        </p>

        <p>Trading in <em>{view.companyName}</em> has also been compared to your peer group, which includes <em>{view.peers}</em></p>

        <p>During {view.period}, you performed as follows compared to your peers:
        </p>

        <table>
            <tbody>
            { view.peerPerformance.map((x, index)=><tr key={index}>
                    <td>
                    <span>{x.name}</span>
                    
                    </td>
                    <td>
                    <span className={"peer-performance-percentage " + getPercentageStyle(x.percentage)}>
                    {x.percentage > 0 ? '+':''}
                    {x.percentage}%

                    </span>
                    </td>
                    
                </tr>)}

            </tbody>
            

            </table>

       </div>
      

    </Section>
    
    
    
}

export default ActivitySummary;