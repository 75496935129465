import React from 'react';
import { Invoice } from "../model/Invoice";
import moment from 'moment';

export const toFormName = (displayName:string) =>{
    return lowercaseFirstLetter(displayName.replace(/ /g, ''));
}

function lowercaseFirstLetter(text:string) {
    return text ? text.charAt(0).toLowerCase() + text.slice(1) : text;
}
export function uppercaseFirstLetter(text:string|undefined) {
    
    return text ? text.charAt(0).toUpperCase() + text.slice(1) : text;    
}

export const toSydneyDate = (dateIsoString:string):string=>{
    const date = moment(dateIsoString).tz('Australia/Sydney');
    return date.format('D MMM YYYY h:mm A')    
}

export const getBaseAddress = ():string =>{
    let baseAddress: string = `${window.location.protocol}//www.investi.com.au`;    
    if(window.location.host.indexOf('127.0.0.1') > -1 ||window.location.host.startsWith('localhost')){
        baseAddress = "http://localhost:8081";  
        console.log(`using baseAddress: ${baseAddress}`)
      }else if(window.location.host === "admin-dev.investi.com.au"){      
        baseAddress = `${window.location.protocol}//dev.investi.com.au`;
        console.log(`using baseAddress: ${baseAddress}`)
      }
      return baseAddress;
}

export const invoiceStringGetter = (invoice:Invoice):React.ReactNode =>  {
    
    const ref = !invoice.reference ? '' : `Ref: ${invoice.reference}`;
    const text = `:: ${invoice.status} :: $${invoice.total} :: ${invoice.contact.name} :: Issue Date:${moment(invoice.date).format('DDMMMYY')} ${ref}`;
    const xeroRepeatingInvoiceId = invoice.repeatingInvoiceID

    const tsx = <span>
        <a target="_blank"
                        href={getInvoiceUrl(invoice.invoiceID)}
                        rel="noopener noreferrer"   
                        className='invoice-link'             
                    >     {invoice.invoiceNumber} </a>
                    <span>{text}</span>

                    { xeroRepeatingInvoiceId  && <a target="_blank"
                href={getRepeatingInvoiceUrl(xeroRepeatingInvoiceId)}
                rel="noopener noreferrer"
                className='repeating-invoice-link'
            >     [Repeating Invoice] </a> }
    </span>
    return tsx;
}
export const getInvoiceUrl = (invoiceID:string)=>{
    return `https://go.xero.com/AccountsReceivable/View.aspx?InvoiceID=${invoiceID}`;
} 
export const getRepeatingInvoiceUrl = (xeroRepeatingInvoiceId:string)=>{
    return `https://go.xero.com/RepeatTransactions/Edit.aspx?invoiceID=${xeroRepeatingInvoiceId}&type=AR`;
} 

export function to(promise:Promise<any>) {  
    return promise.then(data => {
       return [null, data];
    })
    .catch(err => [err]);
  }