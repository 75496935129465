import React, { useState } from 'react';
import SystemSettingsViewModel from '../model/SystemSettingsViewModel';
import FormTextItem from './form/FormTextItem';
import api from '../api/api';
import NumberInput from './form/NumberInput';
import FormSwitchItem from './form/FormSwitchItem';
import AnimatedSaveButton from './AnimatedSaveButton';
import useAsyncEffect from 'use-async-effect';
import { FileCleanupResult } from '../model/FileCleanupResult';
import SortableTable from './table/SortableTable';
import ReconcileResultPage from './systemSettings/ReconcileResultPage';
import { Link } from 'react-router-dom';
import { DiskReportResult } from '../model/DiskReportResult';
import moment from 'moment'

const headers:string[] = [
   'Directory',
   'Filename',
   'Size',
]

const cellFormatter = (columnName:string, cell:any):React.ReactNode => {
    if(columnName==="Last Used In Report" && cell){
        
        return moment(cell).format('DD MMM YY')        
    }
}

const SystemSettingsPage: React.FC = () => {

    const [settings, setSettings] = useState({} as SystemSettingsViewModel)
    const [fileCleanupResult, setFileCleanupResult] = useState<FileCleanupResult>();
    const [reconcileResultId, setReconcileResultId] = useState<string>();
    const [reconcileDays, setReconcileDays] = useState<number>(0);
    const [showTable, setShowTable] = useState<boolean>(false);
    const [lastReconcileId, setLastReconcileId] = useState<string>();
    const [diskReportResult, setDiskReportResult] = useState<DiskReportResult>();
    const [includeBillable, setIncludeBillable] = useState<boolean>(false);
    const [testEmailAddress, setTestEmailAddress] = useState<string>('');

    useAsyncEffect(async (isMounted) => {
        let settings = await api().getSystemSettings();
        let id = await api().getLastReconcileId();
        if (isMounted()) {
            setSettings(settings);
            setLastReconcileId(id)
        }

    }, [])

    const onChangeCheckbox = (name: string, value: string | boolean): void => {
        updateState(name, value);
    }

    const onChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        updateState(e.target.name, e.target.value)
    }

    const updateState = (name: string, value: string | boolean | number) => {
        const s1 = { ...settings } as any;        
        s1[name] = value;
        setSettings(s1);
    }

    const onNumberInputChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
        let value:string|number = e.target.value;
        if(value && (value as string).length){
            value = +value;
        }
        
        updateState(e.target.name, value)
    }

    const save = async () => {

        await api().saveSystemSettings(settings);
        return true;
    }

    const fileCleanup = async ():Promise<boolean> => {

        setFileCleanupResult(undefined);
        let result = await api().getFileCleanupResult();
        setFileCleanupResult(result);
        return true;
    }

    const reconcile = async (): Promise<boolean> => {

        if (window.confirm('Run Reconcile?')) {
            let result = await api().reconcile(reconcileDays);
            setReconcileResultId(result.id);
        }

        return true;
    }

    const sendTestEmail = async (): Promise<boolean> => {

        if (window.confirm(`Send Test Email to ${testEmailAddress} ?`)) {
            let sendEmailResult = await api().sendEmail({ email: testEmailAddress });
            console.log('result', sendEmailResult)
        }

        return true;
    }

    const diskReport = async ():Promise<boolean> => {

        setDiskReportResult(undefined);
        let result = await api().getDiskReportResult({includeBillable });
        setDiskReportResult(result);
        return true;
    }

    const data:any[][] = [];
    let cleanupTotal = 0;
    let command = '';
    if(fileCleanupResult){
        for(let row of fileCleanupResult.onDiskButNotInDatabase){
            cleanupTotal += row.size;
            const arr:any= [
                row.directory,
                row.filename,
                row.size,            
            ]
            const filenamePart = !!row.filename ? `/${row.filename}` :''
            command += `rm /opt/investi/wwwroot/announcements/${row.directory}${filenamePart}\n`
            data.push(arr)
        }
    }

    let diskReportData = diskReportResult && diskReportResult.items.map(row=> [
        row.ticker,
        row.diskSpace,
        row.website,            
        row.apiKey,            
        row.lastUsedInReport||''
    ]  ) ;
    
    
    const formatLength = (length:number, text:string) :string => {
        return (length > 0 ? (length +' ') : 'No ') + text;
    }

    return <div className="padding system-settings">
        <div className='column1'>
            <div className="space-between">
                <h1 style={{ margin: "10px 0" }}> Settings</h1>
                <AnimatedSaveButton onClick={save} />
            </div>

            <div className="input-group">

        <div className="form-item">
                <label htmlFor="">ASX Exchange Service</label>
                <select value={settings.announcementService} name="announcementService" onChange={onChange}>
                    <option>asx</option>
                    <option>asx2</option>
                    <option>westpac</option>
                    <option>iress</option>
                    <option>mock</option>
                </select>
            </div>

            <FormTextItem label="Error Email" value={settings.errorEmail} onChange={onChange} />

            <div className="form-item">
                <label htmlFor="">Hourly AutoSend Limit</label>
                <NumberInput value={settings.hourlyAutoSendLimit} name="hourlyAutoSendLimit" onChange={onNumberInputChanged} />
            </div>

            <div className="form-item">
                <label htmlFor="">Daily AutoSend Limit</label>
                <NumberInput value={settings.dailyAutoSendLimit} name="dailyAutoSendLimit" onChange={onNumberInputChanged} />
            </div>

            <FormTextItem label="Disable Poll Until" value={settings.disablePollUntil!} onChange={onChange} placeholder={'e.g.' + new Date().toISOString()} />

            <FormSwitchItem label="Use Nightmare" name="useNightmare" value={settings.useNightmare} onChange={onChangeCheckbox} />

            

           
            

        </div>

        </div>
        <div className='column2' style={{'marginLeft':'2rem'}}>
        
        <br/><br/><br/><br/>
        <div className="form-item">
            <AnimatedSaveButton onClick={fileCleanup} startingText="File Cleanup" progressText="Wait..." completedText="Done!" />
        </div>           
            

            
            <div className="form-item">
                <div className='flex-center'>
                <label htmlFor="">Reconcile Days</label> <AnimatedSaveButton onClick={reconcile} startingText="Reconcile" progressText="Reconciling..." completedText="Reconcile" />
                </div>
                
                <NumberInput value={reconcileDays}  onChange={(e:any)=>setReconcileDays(+e.target.value)} />
                
                { lastReconcileId && <Link to="#" onClick={()=> setReconcileResultId(lastReconcileId)}>Load Last Reconcile ({lastReconcileId})</Link>}
            </div>
        
        { reconcileResultId && <ReconcileResultPage id={reconcileResultId} />}        

        

{ fileCleanupResult && <>
                
    
    <h3>{formatLength(fileCleanupResult.missingOnDisk.length, 'Missing on Disk')} </h3>
    { fileCleanupResult.missingOnDisk.length > 0 && <>                
        <ul>
        { fileCleanupResult.missingOnDisk.map((x,i) => <li key={i}>{x.filename}</li>)}
    </ul>
    </>}

    <h3>{formatLength(fileCleanupResult.duplicates.length, 'Duplicates')}</h3>
    { fileCleanupResult.duplicates.length > 0 && <>                
        <ul>
        { fileCleanupResult.duplicates.map((x,i) => <li key={i}>{x}</li>)}
    </ul>
    </>}

    <h3>{formatLength(fileCleanupResult.onDiskButNotInDatabase.length, 'Files To Delete')}</h3>
    { fileCleanupResult.onDiskButNotInDatabase.length > 0 && <>
        <p>Total Size: {Math.round(cleanupTotal/1024/1024).toLocaleString()} mB </p>
        <button onClick={(e)=>setShowTable(!showTable)}>Show Table</button>
        { showTable && <SortableTable headers={headers} data={data}  />}

        <pre>
            {command}
        </pre>
    </>}

</>            
    }


<div className="form-item">
    <AnimatedSaveButton onClick={diskReport} startingText="Disk Report" progressText="Wait..." completedText="Done!" />
    <FormSwitchItem label="Include Billable" value={includeBillable} onChange={(name,value)=>setIncludeBillable(value as boolean)} />
    </div>

    { diskReportResult && <>

         <SortableTable 
            headers={["Ticker", "DiskSpace (mB)","Website","ApiKey", "Last Used In Report"]} 
            data={diskReportData || []}
            cellFormatter={cellFormatter}
            />       
                        
            
            </>            
                }

        <div className="form-item">
                <div className='flex-center'>
                 <AnimatedSaveButton onClick={sendTestEmail} startingText="Send Test Email" progressText="Sending..." completedText="Sent" />
                </div>
                
                <FormTextItem label="Enter Email" value={testEmailAddress}  onChange={(e:any)=>setTestEmailAddress(e.target.value)} />
                
                
            </div>
                

        </div>

    </div>
}

export default SystemSettingsPage;