import React from 'react'
import styled from 'styled-components';
import { ReportPeriod } from '../../model/ReportPeriod';

interface IProps {
    reportPeriod: ReportPeriod
    startDate:string
    endDate:string
}


const Section = styled.section`

`;

const ReportPeriodSection:React.FC<IProps> = (props:IProps)=>{
           
    const { reportPeriod } = props;
    return <Section>
        <h3>{reportPeriod.companyName}</h3>
       
        <p>Period: ({props.startDate} - {props.endDate})</p>

        <p title={`Shares on issue: ${reportPeriod.quotedSharesOnIssue}`}>Market Cap at end of Period: <em>${reportPeriod.marketCap}</em></p>

        <p>Vwap for Period: <em>${reportPeriod.vwap}</em></p>
      

    </Section>
    
    
    
}

export default ReportPeriodSection;