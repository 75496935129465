import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { FaCog, FaChevronRight } from 'react-icons/fa';
import api from '../api/api';
import { useSelector } from 'react-redux';
import { IGlobalState } from '../model/IGlobalState';
import * as Roles from '../model/Roles';


const Header:React.FC = () => {
  
  const [ menuVisible, setMenuVisible ] = useState(false);
  const user = useSelector((state:IGlobalState) => state.user);
  const toggleSettings = () : void => { 
    setMenuVisible(!menuVisible)   
  }
  
  const hideMenu = () =>{
    setMenuVisible(false)
  }

  const logout = async () => {
    await api().logout();    
    window.location.href = "/";
  }

  const isAdmin = user.roles.hasOwnProperty(Roles.Admin)

 return <header className="header">
 <div className="space-between">
   <Link to="/" onClick={hideMenu}>
     <img src="/images/logo.svg" alt="logo" className="front-page-logo"/>           
   </Link>
   <FaCog size={24} className="header__settings" onClick={toggleSettings} />
 </div>
 { menuVisible && 
     <div className="header__menu">
       <ul>
         <li style={{textTransform: 'none'}}>Logged in as {user.username}</li>
        { isAdmin && <>
          <li><Link to="/create" onClick={hideMenu}>Add New Stock</Link> <FaChevronRight /></li>
         <li><Link to="/system-settings" onClick={hideMenu}>System Settings </Link> <FaChevronRight /></li>
         <li><Link to="/xero-page" onClick={hideMenu}>Xero Integration</Link> <FaChevronRight /></li>
         <li><Link to="/orders" onClick={hideMenu}>Orders</Link> <FaChevronRight /></li>
        </>}
        { user.roles.hasOwnProperty(Roles.EditUsers) && <>
         <li><Link to="/users" onClick={hideMenu}>Users</Link> <FaChevronRight /></li>
        </>}
        
         <li><Link to="#" onClick={logout}>Logout </Link> <FaChevronRight /></li>
       </ul>
     </div>
 }
</header>

  
}

export default Header;

