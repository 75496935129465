import React, { useState } from 'react';
import useAsyncEffect from 'use-async-effect';
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux';
import SortableTable from '../table/SortableTable';
import { loading } from '../../actions/Loading';
import api from '../../api/api';
import { ClientDashboardResult } from '../../model/ClientDashboardResult';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { IGlobalState } from '../../model/IGlobalState';
import { HasStockRole } from '../../model/Roles';
import * as Roles from '../../model/Roles';

const Style = styled.div`

`





const ClientDashboard: React.FC= () => {
    const dispatch = useDispatch();
    const [ item, setItem ] = useState<ClientDashboardResult>();
    const headers = [ 'Ticker', 'Created', 'API Key', 'Website', 'Mailchimp','Confirmation Email' ]
    const user = useSelector((state: IGlobalState) => state.user)
    const hasInvoicingRole = HasStockRole(user, Roles.Invoicing);
    if(hasInvoicingRole){
        headers.push('Invoice')
    }
    
    
    useAsyncEffect(async (isMounted:()=>boolean)=> {
        dispatch(loading(true));
        const result = await api().getClientDashboardResult();
        if(isMounted()){
            setItem(result)
        }
        dispatch(loading(false));
        
      }, []);

      const cellFormatter = (columnName:string, cell:any):React.ReactNode => {
        if(columnName==='Ticker'){
            if(item != null){
                const stock = item.stocks.find(x=>x.combinedTicker===cell)!;
                const newTo = { 
                    pathname: `/client-stocks/${stock.combinedTicker}`, 
                    id: stock.id 
                  };
                return <Link to={newTo}  >{cell}</Link>
            }            
        }
        else if(columnName ==='Website'){
            return <a target="_blank"
            href={cell}
            rel="noopener noreferrer"
        >     <span>{cell}</span>
        </a>
        }
        else if(columnName ==='Confirmation Email'){
            const email = cell as string;            
            if(email != null){
                const addresses = email.split(/[,;]+/)
                return <div>
                    { addresses.map((x,i)=> <div key={i}>{x}</div>)}
                </div>
            }
        }
        else if(columnName ==='Invoice'){
            if(item != null){
                
                const stock = item.stocks.find(x=>x.invoiceId===cell)!;
                if(stock != null && stock.invoiceTotal){
                    const url = api().getUrl(`/api/invoice?ticker=${stock.combinedTicker}&invoiceId=${stock.invoiceId}`);

                    const unpaid = stock.invoiceStatus !== "PAID";
                    let daysLate:number|undefined;
                    let overdueClass = "";
                    if(unpaid){
                        const duration = moment.duration(moment().diff(moment(stock.invoiceDate)));
                        daysLate = Math.floor(duration.asDays());
                        if(daysLate > 90){
                            overdueClass = "overdue";
                        }
                    }

                    return <>
                    <a target="_blank"
                    href={url}
                    rel="noopener noreferrer">     <span>{stock.invoiceNumber}</span>
                        </a>
                    <span> - ${stock.invoiceTotal.toLocaleString()} - 
                    <span>{moment(stock.invoiceDate).format('DDMMMYY')} - </span>
                    {unpaid ? "Unpaid - " : "Paid"}
                    { daysLate && <> <span className={overdueClass}>{daysLate} days overdue</span> </>}
                    </span>
                    </>
                    
                }
            }
        }
        return null;
    }

    const data:any[][] = [];
    if(item){
        for(let stock of item.stocks){
            let arr:string[] = []
            
            arr = [
                stock.combinedTicker,
                stock.created,            
                stock.apiKey || '',                                                    
                stock.websiteUrl || '',
                stock.mailchimp ? '✓' : '✕',            
                stock.confirmationEmail,    
                        
            ]
            if(hasInvoicingRole){
                arr.push(stock.invoiceId)
            }
            
            data.push(arr)
        }
    }
    



    return <div>
        
        <Style>
        <SortableTable headers={headers} data={data} cellFormatter={cellFormatter}  />
    </Style>
        {/* { entries.map(x=> <ClientStockItem key={x[0]} stockId={x[0]} expanded={entries.length===1}/>)} */}

    </div>
}

export default ClientDashboard