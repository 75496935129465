import React, { useState } from 'react';
import styled from 'styled-components'
import useAsyncEffect from 'use-async-effect';

import ServiceOrder, { } from '../../model/ServiceOrder';
import api from '../../api/api';
import AlertEx from '../AlertEx';
import ServiceOrderItem from './ServiceOrderItem'
const Style = styled.div`

ul {
    margin: 0;
}

.row {
    display: flex;
    align-items: start;

    & > * {
        margin: 3.5rem 0.5rem 0 0;
    }
}
`;

interface ServiceOrderWrapper {
    order:ServiceOrder
    ticker:string
    reference:string
    expanded:boolean
}
const ServiceOrdersPage:React.FC = () => {
    const [ orders, setOrders ] = useState<ServiceOrderWrapper[]>([]);
    

    useAsyncEffect(async (isMounted)=>{
        const result = await api().getServiceOrders();
        if(isMounted()){
            setOrders(result.serviceOrders.map(order=> ({ order, expanded: false, ticker: order.ticker, reference: '' })));
        }
    }, []);

    const create = async (order:ServiceOrder)=>{
        const wrapper = orders.find(x=>x.order.id === order.id)!;
        const request = { serviceOrderId: order.id, ticker: wrapper.ticker, reference: wrapper.reference };
        const result = await api().createStockFromServiceOrder(request);
        if(!result.errorMessage){
            window.location.href = result.url;
        }else{
            AlertEx(result.errorMessage);
        }
        return true;
    }

   
    const orderFieldChanged = (fieldName:string, value:string, id:string) => {
        const newArr = orders.map(x=> {
            if(x.order.id === id){
                const obj:any = {
                    ...x
                }
                obj[fieldName] = value;
                return obj;
            }else{
                return x;
            }
        });
        setOrders(newArr);
    }


    return <Style>
        <h2>Service Orders</h2>

        { orders.map((x,i)=> <ServiceOrderItem 
        key={i} 
        order={x.order} 
        ticker={x.ticker}
        reference={x.reference}
        create={create} 
        orderFieldChanged={orderFieldChanged} 
        />) }
    </Style>
}

export default ServiceOrdersPage;