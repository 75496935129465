import React, { useState } from 'react';
import styled from 'styled-components'
import useAsyncEffect from 'use-async-effect';
import api from '../../api/api';
import { GetUsersResult, UserItem } from '../../model/GetUsersResult';
import { Link } from 'react-router-dom';
const Style = styled.div`

.row {
    margin: 1rem 0;
}
`;

const getUserRow = (x:UserItem) =>{
return <div className='row' key={x.id}><Link to={`/${x.id}`}>{x.username}</Link></div>
}

const UsersPage:React.FC = () => {
    
    const [ result, setResult ] = useState<GetUsersResult>();
    useAsyncEffect(async (isMounted)=>{
        const result = await api().getUsers();
        if(isMounted()){
            setResult(result)
        }
    }, []);
    


    return <Style>
        <h2>Users</h2>
        <div><Link to={`/users/new`}><button>Add New User</button></Link></div>
    <div>{ !!result && result!.users.map(getUserRow)}</div>
    </Style>
}

export default UsersPage;