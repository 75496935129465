import React from 'react';
import { toFormName } from '../../util/util';

interface IFormTextAreaItemProps{
    label:string;
    name?:string;
    value:string|undefined;
    onChange:(e:any)=>void;
    disabled?:boolean;
    placeholder?:string;
    helperText?:string;
    className?:string;
    required?:boolean;
    rows?:number|undefined;
}
const FormTextArea = (props:IFormTextAreaItemProps) => {
    const name = props.name || toFormName(props.label);
    return (
    
        <div className={ "form-item " + (props.className||'')}>
            <div>
            <label htmlFor="">{props.label}</label>
            { props.helperText && <small style={{marginLeft:'10px'}}>{props.helperText}</small>}
            </div>
            <textarea 
            value={props.value} 
            name={name} 
            onChange={props.onChange} 
            disabled={props.disabled} 
            placeholder={props.placeholder} 
            required={props.required} 
            rows={props.rows}
            >

            </textarea>
        </div>
    )
}


export default FormTextArea;