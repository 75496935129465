import React from 'react';
import { FaEnvelope, FaMailchimp } from 'react-icons/fa';
import Switch from '../Switch';

interface IProps{
    children:any;
    emailName:string;
    mailchimpName:string;
    emailIsChecked:boolean;
    mailchimpIsChecked:boolean;
    onChange:(name:string, value:string|boolean)=>void;
}
const ConfigNotificationSettings = (props:IProps) => {
    
    return (
    
        <div className="form-item">
            <label>{props.children}</label>
            <div className="config__send-settings">

                <div className="config__send-settings-item">
                    <FaEnvelope size={16} color="grey" />
                    <span>Email</span>
                    <Switch isChecked={props.emailIsChecked} name={props.emailName} onChange={props.onChange} />
                </div>

                <div className="config__send-settings-item">
                    <FaMailchimp size={16} color="grey" />
                    <span>Mailchimp</span>
                    <Switch isChecked={props.mailchimpIsChecked!} name={props.mailchimpName} onChange={props.onChange} />
                </div>
            </div>
        </div>
    )
}

export default ConfigNotificationSettings;