import React from 'react';
import { setBillableFilter, setTextFilter } from '../../actions/Filters'
import { useDispatch, useSelector } from 'react-redux';
import TextInput from '../form/TextInput';
import { IGlobalState } from '../../model/IGlobalState';

interface IProps{
    
}

const DashboardFilter:React.FC<IProps> = (props:IProps)=> {
    
    const filters = useSelector((state:IGlobalState)=> state.filters);
    const dispatch = useDispatch();
    


      return <>
      <div className="dashboard-filter">
      <TextInput 
      className="dashboard-filter__text"
      value={filters.text}
      placeholder="filter..."
      type="search"            
      onChange={(e:any)=> dispatch(setTextFilter(e.target.value))}/>
      
      <select value={filters.billable}
      onChange={(e:any)=> dispatch(setBillableFilter(e.target.value))}
      >
                  <option value=''>All (non-Archived)</option>
                  <option>Billable</option>
                  <option>Billable - Unpaid</option>
                  <option>Non-Billable</option>
                  <option>Archived</option>
              </select>

    </div>

    
      </>
}


export default DashboardFilter