import React from 'react';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { toFormName } from '../../util/util';
import moment from 'moment';

interface IFormDateItemProps{
    label:string;
    name?:string;
    value:string|null;
    onChange:(e:any)=>void;
    disabled?:boolean;
    placeholder?:string;
    helperText?:string;
    className?:string;
    required?:boolean;
}

const FormDateItem = (props:IFormDateItemProps) => {
    const name = props.name || toFormName(props.label);
    const onChange = (date: Date|null) => {
        
        const value:string = date == null ? "" : moment(date).format('DD-MMM-YYYY');        
        const e =   { target: { name, value }};
        props.onChange(e)
    }

    const value = props.value != null ? new Date(props.value) : null;
    return (
    
        <div className={ "form-item " + (props.className||'')}>
            <div>
            <label htmlFor="">{props.label}</label>
            { props.helperText && <small style={{marginLeft:'10px'}}>{props.helperText}</small>}
            </div>
            <DatePicker 
            selected={value}
            name={name} onChange={onChange} 
            disabled={props.disabled} 
            required={props.required}
            dateFormat="dd-MMM-yyyy"
            />            
        </div>
    )
}


export default FormDateItem;