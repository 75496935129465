import React from 'react';
import Stock from '../../model/DashboardStock' 
import DashboardFilter from './DashboardFilter';
import { useDispatch, useSelector } from 'react-redux';
import selectStocks from '../../selectors/dashboard-stocks'
import DashboardItem from './DashboardItem';
import api from '../../api/api';
import { loading } from '../../actions/Loading'
import DashboardTable from './DashboardTable'
import styled from 'styled-components'
import useAsyncEffect from 'use-async-effect';
import { IGlobalState } from '../../model/IGlobalState'
import * as Roles from '../../model/Roles';

interface IProps {
  
}

const Style = styled.div`
@media (min-width: 768px) {
  .dashboard-item-container{
      display:none;
    } 
  }

  .filter-info {
    margin-top: 5px;
    display:flex;
    justify-content: space-between;
  }
  .num-displayed {
    font-size:10px;
    font-style:italic;
  }

  table {
    margin-top: 10px;
  }

`
const Dashboard: React.FC<IProps> = (props: IProps) => {
  let dashboard_stocks = useSelector((state: IGlobalState) => state.dashboard_stocks)
  const filters = useSelector((state: IGlobalState) => state.filters)
  const user = useSelector((state: IGlobalState) => state.user);
  const stocks = selectStocks(dashboard_stocks, filters);
  const dispatch = useDispatch();

  useAsyncEffect(async ()=> {
    dispatch(loading(true));
    await api().loadDashboardStocks();
    dispatch(loading(false));
  }, []);

  const showTotal = user.roles.hasOwnProperty(Roles.InvoiceTotal)
  let total:number = 0;
  if(showTotal){
    total = stocks.reduce((a,b)=> a + (b.repeatingInvoice?b.repeatingInvoice.total:0), 0)
  }
  
  
  return <Style className="padding">

    <DashboardFilter />
  
    <div className="filter-info">
      <div><span>Filters:  </span>
        {!!filters.text && <em>{filters.text}</em>}
        {!!filters.billable && <em> {filters.billable}</em>}
        {!filters.text && !filters.billable && <em> None</em>}
      </div>
      { showTotal && (!filters.billable || filters.billable==='Billable')  && <div>Repeating Invoice Total: <span>${total.toLocaleString()}</span></div> }
    </div>

    

    <div className="dashboard-item-container">
      {stocks.map((x: Stock, index: number) => <DashboardItem key={index} stock={x} />)}
    </div>

    <p className="num-displayed">Showing {stocks.length} of {dashboard_stocks.length}</p>
    <DashboardTable stocks={stocks} />

  </Style>
}

// const areStatesEqual = (next: any, prev: any) => {
//   return prev.filters.text === next.filters.text
//   && prev.filters.billable === next.filters.billable
//     && prev.dashboard_stocks === next.dashboard_stocks;
// }
export default Dashboard