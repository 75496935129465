import { User } from "../model/User";


const reducer = (state = {}, action:{ type:string, value: User}) => {
  switch(action.type){
    case 'SET_USER':
      return action.value;
    
    default:
      return state;
  }
}

export default reducer;