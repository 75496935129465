import React, { useState } from 'react'
import styled from 'styled-components';
import FormDateItem from '../form/FormDateItem';
import { FaSpinner } from 'react-icons/fa';

interface IProps {  
    startDate:string
    endDate:string
    onGenerateReport:(startDate:string, endDate:string)=>void  
    generatingReport:boolean
}

const Wrapper = styled.div`
display: flex;
 > div {
    margin-right: 1rem;
 }
.saveButton{
    align-self:center;
}
`


const ReportSearchBar:React.FC<IProps> = (props:IProps) => {
    
    const [ startDate, setStartDate ] = useState<string>(props.startDate);
    const [ endDate, setEndDate ] = useState<string>(props.endDate);
    

    return <Wrapper>
        <FormDateItem label="Start Date" name='startDate' value={startDate} onChange={e=>setStartDate(e.target.value)}  />            
        <FormDateItem label="End Date" name='endDate' value={endDate} onChange={e=>setEndDate(e.target.value)}  />            
        <button className="saveButton" onClick={e => props.onGenerateReport(startDate, endDate)} disabled={props.generatingReport}> 
            <span>{props.generatingReport ? 'Generating...':'Generate'}</span>
                {props.generatingReport && <FaSpinner className="fa-spin" size={16} color="white" />}
        </button>
        
    </Wrapper>
}

export default ReportSearchBar