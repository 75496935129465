import React from 'react';
import TextInput from './TextInput';
import { toFormName } from '../../util/util';

interface IFormTextItemProps{
    label:string;
    name?:string;
    value:string|number|null;
    onChange:(e:any)=>void;
    disabled?:boolean;
    placeholder?:string;
    helperText?:string;
    className?:string;
    required?:boolean;
    children?:string|React.ReactElement|undefined
}
const FormTextItem = (props:IFormTextItemProps) => {
    const name = props.name || toFormName(props.label);
    return (
    
        <div className={ "form-item " + (props.className||'')}>
            <div>
            <label htmlFor="">{props.label}</label>
            { props.helperText && <small style={{marginLeft:'10px'}}>{props.helperText}</small>}
            </div>
            <TextInput value={props.value} name={name} onChange={props.onChange} disabled={props.disabled} placeholder={props.placeholder} required={props.required} />
            { props.children }
        </div>
    )
}


export default FormTextItem;